<template>
  <div class="steps d-flex justify-content-between align-items-center mb-4" :class="steps.length < 3 ? 'steps--small' : ''">
            <template v-for="(item, key) in this.steps" :key="item.id">
            <div class="steps_item d-flex align-items-center" :class="activeStep === item.id ? 'active' : ''" >
                <div class="steps_num title_block me-2">{{key + 1}}</div>
                <div class="steps_name title_bold_16">{{item.name}}</div>
            </div>
            <div class="steps_arrow opacity-25">
                <vue-feather type="chevron-right"></vue-feather>
            </div>
            </template>
        </div>
</template>

<script>
export default {
    props: ['steps', 'activeStep']
}
</script>

<style>

</style>