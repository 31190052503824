<template>
<div class="header_navbar bg-white">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <header-navbar-item v-for="item in MAIN_MENU" :el="item" :key="item.id" />
                </ul>
            </div>
        </nav>
    </div>
</div>
</template>

<script>
import HeaderNavbarItem from './HeaderNavbarItem.vue'
import {
    mapGetters,
    mapActions,
} from "vuex";

export default {
    components: {
        HeaderNavbarItem
    },
    data() {
        return {
            menuItems: []
        }
    },
    async mounted() {
        this.fetchMenu();
        this.fetchGeo();
    },
    methods: {
        ...mapActions({
            fetchMenu: "global/fetchMenu",
            fetchGeo: "global/fetchGeo",
        }),
    }, 
    computed: {
        ...mapGetters({
            MAIN_MENU: "global/getMainMenu"
        }),
    }
}
</script>

<style>

</style>
