<template>
<nav class="footer_menu d-lg-flex justify-content-between h-100 align-items-center">
    <router-link v-for="menuItem in getMainMenu" :key="menuItem.id" :to="menuItem.link" class="fw-bold d-block mb-4 mb-lg-0">{{menuItem.name}}</router-link>
</nav>
</template>

<script>
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            menuItems: []
        }
    },
    computed: {
        ...mapGetters({
            getMainMenu: "global/getMainMenu"
        }),
    }
}
</script>

<style>

</style>
