<template>
	<default-layout>
		<template #content>
			<page-title title="Архив результатов" :multiple_titles="[
                {
                    title: 'Рейтинги',
                    route_name: 'rating'
                },
                {
                    title: 'Рекорды',
                    route_name: 'records'
                },
				{
                    title: 'Турнирные таблицы',
                    route_name: 'tournaments'
                },
				{
                    title: 'Архив результатов',
                    route_name: 'results'
                },
            ]" />
			<div class="container mb-5">
				<FilterWrap>
					<FilterSelect
						label="Мероприятие"
						v-model="filter.event_cat"
						:className="'col-12 col-md-4 col-lg-3'">
						<option value="0">Все</option>
						<option v-for="(item, i) in filter_data.event_cats" :key="i" :value="item">
							{{ item }}
						</option>
					</FilterSelect>
					<FilterSelect
						label="Год"
						v-model="filter.year"
						:className="'col-12 col-md-4 col-lg-3'">
						<option value="0">Все</option>
						<option v-for="(item, i) in filter_data.years" :key="i" :value="item">
							{{ item }}
						</option>
					</FilterSelect>
					<FilterClear @clicked="clearFilter()" />
				</FilterWrap>
			</div>
			<div class="page_section">
				<div v-if="resultsByYears.length" class="container">
					<template v-for="(year, i) in resultsByYears" :key="i">
						<div class="record_category mb-5">
							<h2 class="section_title_medium">{{ year.year }} год</h2>

							<template v-for="(item, n) in year.items" :key="n">
								<EventResultCard :item="item" />
							</template>
						</div>
					</template>
				</div>
				<PageAlertBox
					v-if="!resultsByYears.length"
					:alert="{
						class: 'alert-warning',
						title: 'Записей по вашему запросу не найдено',
						text: 'Пожалуйста, попробуйте изменить критерии фильтрации',
					}" />
			</div>
		</template>
	</default-layout>
</template>

<script>
import PageAlertBox from '../components/PageAlertBox.vue'
import FilterClear from '../components/filter/FilterClear.vue'
import FilterSelect from '../components/filter/FilterSelect.vue'
import FilterWrap from '../components/filter/FilterWrap.vue'
import EventResultCard from '../components/cards/EventResultCard'
import PageTitle from '../components/PageTitle.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import RecordCard from '@/components/cards/RecordCard.vue'
import { mapGetters, mapActions } from 'vuex'
import setMeta from '@/views/seoMeta'
export default {
	components: {
		EventResultCard,
		DefaultLayout,
		PageTitle,
		RecordCard,
		FilterWrap,
		FilterSelect,
		FilterClear,
		PageAlertBox,
	},
	data() {
		return {
			filter: {
				event_cat: 0,
				year: 0,
			},
			filter_data: {
				event_cats: [],
				years: [],
			},
		}
	},
	async mounted() {
		setMeta(`Архив результатов | Swim4you.ru`, null, null, false)
		await this.fetchPosts()
		this.getFilterData()
		
	},
	methods: {
		...mapActions({
			fetchPosts: 'events/fetchEventResults',
		}),
		clearFilter() {
			this.filter = {
				event_cat: 0,
				year: 0,
			}
		},
		getFilterData() {
			for (let item in this.getPosts) {
                let event_year = this.getPosts[item].year
				if (!this.filter_data.years.includes(event_year)) {
					this.filter_data.years.push(event_year)
				}
                let event_cat = this.getPosts[item].event_cat
                if (!this.filter_data.event_cats.includes(event_cat)) {
                    this.filter_data.event_cats.push(event_cat)
                }
			}

			this.filter_data.years.sort()
		},
	},
	computed: {
		...mapGetters({
			getPosts: 'events/getEventResults',
		}),
		filteredItems(){
			return this.getPosts.filter(item => {
				return (this.filter.event_cat == 0 || item.event_cat == this.filter.event_cat) && (this.filter.year == 0 || item.year == this.filter.year)
			})
		},
        resultsByYears(){
            let obj = _.groupBy(this.filteredItems, 'year')
            return Object.keys(obj).map(v => { return {year: v, items: obj[v]}}).sort((a, b) => b.year - a.year);
        },
/* 		visibleYears() {
			let years = Object.keys(this.getPosts)
				.filter((item) => this.filter.year == item || this.filter.year == 0)
				.reduce((cur, key) => {
					return Object.assign(cur, {
						[key]: this.getPosts[key].filter(
							(item) =>
								this.filter.event_cat == 0 || this.filter.event_cat == item.event_cat
						),
					})
				}, {})

			let sorted_years = Object.keys(years)
				.sort((a, b) => b - a)
				.reduce((obj, key) => {
					obj[key] = years[key]
					return obj
				}, {})

			console.log(sorted_years)

			return years
		}, */
	},
}
</script>

<style></style>
