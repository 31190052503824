<template>
    <div class="card card--stage">
        <div class="img_wrapper">
            <img :src="stage.thumbnail" class="card__img" />
        </div>
        <div class="card__overlay"></div>
        <div
            class="
                card__meta
                text-white
                d-flex
                flex-column
                justify-content-between
            "
        >
            <div
                v-if="status.text"
                class="badge text-decorative text-uppercase small"
                :class="status.badge_class"
            >
                {{ status.text }}
            </div>
            <div class="mb-4">
                <router-link
                    :to="'/stage/' + stage.slug"
                    class="
                        card_title_large
                        text-white text-decoration-none
                        d-block
                        mb-3
                    "
                    >{{ stage.name }}</router-link
                >
                <div class="row gy-3">
                    <div v-if="stage.location_city" class="col-auto">
                        <p class="mb-0 text-muted">Город:</p>
                        <p class="mb-0">{{ stage.location_city }}</p>
                    </div>
                    <div v-if="stage.dates" class="col-auto">
                        <p class="mb-0 text-muted">Даты:</p>
                        <p class="mb-0">{{ stage.dates }}</p>
                    </div>
                    <div
                        v-if="
                            status.dates &&
                            status.name != 'stage_complited' &&
                            status.name != 'stage_now'
                        "
                        class="col-auto"
                    >
                        <p class="mb-0 text-muted">Срок приема заявок:</p>
                        <p class="mb-0">{{ status.dates }}</p>
                    </div>
                </div>
            </div>
            <div v-if="status.name == 'closed'">
                <p class="mb-2 fs-4 fw-bold">Регистрация пока закрыта</p>
                <p class="mb-3">
                    Даты начала и окончания регистрации скоро будут
                    опубликованы.
                </p>
            </div>
            <div v-if="status.name == 'registration_closed_now' || status.name == 'registration_open' || status.name == 'event_not_now' && status.time_to">
                <p v-if="status.name == 'registration_closed_now'" class="fw-700 mb-2 text-warning">До старта регистрации:</p>
                <p v-if="status.name == 'registration_open'" class="fw-700 mb-2">До окончания приема заявок: </p>
                <p v-if="status.name == 'event_not_now'" class="fw-700 mb-2">До начала события:</p>
                <div class="row gy-3">
                    <div class="col-auto">
                        <VueCountdown :time="status.time_to" :transform="transformSlotProps" v-slot="{ days, hours, minutes, seconds }" @end="onCountdownEnd">
                        <div class="big_counter d-flex">
                            <div v-if="days != '00'" class="big_counter__item">
                                <div class="value">{{ days }}</div>
                                <div class="label">Дн.</div>
                            </div>
                            <div v-if="hours != '00'" class="big_counter__item">
                                <div class="value">{{ hours }}</div>
                                <div class="label">Час.</div>
                            </div>
                            <div class="big_counter__item">
                                <div class="value">{{ minutes }}</div>
                                <div class="label">Мин.</div>
                            </div>
                            <div class="big_counter__item">
                                <div class="value">{{ seconds }}</div>
                                <div class="label">Сек.</div>
                            </div>
                        </div>
                        </VueCountdown>
                    </div>
                    <div v-if="status.name == 'registration_open'" class="col-auto">
                        <router-link
                            :to="{ name: 'event_form', query: {id: stage.id, type: 'competition'} }"
                            class="
                                btn btn-primary btn-primary-shadow btn-rounded
                            "
                            >Подать заявку</router-link
                        >
                    </div>
                </div>
            </div>

            <div v-if="status.name == 'stage_complited'">
                <p class="mb-2 fs-4 fw-bold text-success">Этап завершен.</p>
                <template v-if="
                            stage.result_link ||
                            stage.final_protocol_link ||
                            stage.media_photo_id ||
                            stage.media_video_id
                        ">
                    <p class="mb-3">
                        Посмотрите результаты, фото- и видео-альбомы по ссылкам:
                    </p>
                    <div class="media_buttons">
                        <a
                            v-if="stage.result_link"
                            :href="stage.result_link"
                            target="_blank"
                            rel="nofollow"
                            class="btn btn-rounded btn-white btn-sm me-2"
                            ><vue-feather class="text-primary" type="list" />Результаты</a
                        >
                        <a
                            v-if="!stage.result_link && stage.final_protocol_link"
                            :href="stage.final_protocol_link"
                            target="_blank"
                            rel="nofollow"
                            class="btn btn-rounded btn-white btn-sm me-2"
                            ><vue-feather class="text-primary" type="list" />Итоговый протокол</a
                        >
                        <router-link
                            v-if="stage.media_photo_id"
                            :to="'/media/' + stage.media_photo_id"
                            class="btn btn-rounded btn-white btn-sm me-2"
                            ><vue-feather class="text-primary" type="image" />Фото</router-link
                        >
                        <router-link
                            v-if="stage.media_video_id"
                            :to="'/media/' + stage.media_video_id"
                            class="btn btn-rounded btn-white btn-sm"
                            ><vue-feather class="text-primary" type="play" />Видео</router-link
                        >
                    </div>
                </template>
                
                <p v-else class="mb-3">
                    Скоро будут опубликованы результаты, фото- и видео-альбомы
                </p>
                
            </div>
        </div>
        <div class="card__meta__overlay"></div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
//import moment from "moment";
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
    components: {VueCountdown},
    props: ["stage"],
    data() {
        return {
            status: {
                name: "closed",
                text: "",
                dates: null,
                time_to: null,
                badge_class: "bg-warning text-dark",
            },
        };
    },
    mounted() {
        this.getStatus();
    },
    methods: {
        transformSlotProps(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
        onCountdownEnd: function () {
            this.getStatus();
        },
        /* isRegistrationTime() {
			if (this.event.type !== 'competition') return true
			if (!this.event.start_registration_date || !this.event.end_registration_date)
				return false
			let tz = moment.tz.guess();
			let now = moment.tz(tz)
			let offset = now._offset - moment.tz('Europe/Moscow')._offset
			let start = moment(this.event.start_registration_date, 'DD-MM-YYYY HH:mm').add(offset, 'minutes');
			let end = moment(this.event.end_registration_date, 'DD-MM-YYYY HH:mm').add(offset, 'minutes');
			
			if (start < now && now < end) return true
			return
		}, */
        getStatus() {
            let start_registration = null;
            let end_registration = null;
            let start_event = null;
            let end_event = null;

            let current_date = moment(
                this.stage.current_time,
                "DD-MM-YYYY hh:mm"
            )

            if (
                this.stage.start_registration_date &&
                this.stage.end_registration_date
            ) {
                start_registration = moment(
                    this.stage.start_registration_date,
                    "DD-MM-YYYY hh:mm"
                );
                end_registration = moment(
                    this.stage.end_registration_date,
                    "DD-MM-YYYY hh:mm"
                );
                this.status.dates =
                    start_registration.format("DD.MM.YYYY") +
                    " - " +
                    end_registration.format("DD.MM.YYYY");
            } else {
                this.status.text = "Прием заявок пока закрыт";
                return;
            }

            if (current_date < end_registration) {
                if (current_date < start_registration) {
                    this.status.text = "Прием заявок пока закрыт";
                    this.status.time_to = start_registration - current_date;
                    this.status.name = "registration_closed_now";
                    return;
                }
                this.status.text = "Прием заявок уже идет";
                this.status.time_to = end_registration - current_date;
                this.status.name = "registration_open";
                this.status.badge_class = "bg-success";
                return;
            }

            if (this.stage.start_event_date && this.stage.end_event_date) {
                start_event = moment(
                    this.stage.start_event_date,
                    "DD-MM-YYYY hh:mm"
                );
                end_event = moment(
                    this.stage.end_event_date,
                    "DD-MM-YYYY hh:mm"
                );
                this.status.dates =
                    start_event.format("DD.MM.YYYY") +
                    " - " +
                    end_event.format("DD.MM.YYYY");
            } else {
                this.status.text = "Прием заявок окончен";
                this.status.name = "registration_end";
                this.status.badge_class = "bg-danger";
                return;
            }

            if (current_date < end_event) {
                if (current_date < start_event) {
                    this.status.text = "Прием заявок окончен";
                    this.status.time_to = start_event - current_date;
                    this.status.name = "event_not_now";
                    this.status.badge_class = "bg-danger";
                    return;
                }
                this.status.text = "Этап уже идет";
                this.status.name = "stage_now";
                this.status.time_to = end_event - current_date;
                this.status.badge_class = "bg-success";
                return;
            }

            this.status.text = "Этап завершен";
            this.status.name = "stage_complited";
            this.status.badge_class = "bg-danger";
            return;
        },
    },
    computed: {
    },
};
</script>

<style>
</style>