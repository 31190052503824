<template>
<router-link :to="'/media/' + item.slug" class="card" :class="this.class">
    <img :src="item.thumbnail" class="card__img" />
    <div v-if="item.date" class="card__date small">
        <vue-feather type="calendar"></vue-feather> {{item.date}}
    </div>
    <div class="card__meta">
        <div class="d-flex align-items-center mb-3">
            <span class="media_badge">
                <vue-feather :type="item.type == 'video' ? 'youtube' : 'image'"></vue-feather>
            </span>
            <span class="text-light fw-bold ms-2 fs-5">{{ item.type == 'video' ? 'Видео' : 'Фото'}}</span>
        </div>
        
        <p v-if="item.event_name" class="text-decoration-none text-light text-uppercase mb-0 opacity-50 small">
            {{item.event_name}}
        </p>
        <p v-if="item.name" class="card_title_medium d-block text-decoration-none text-light mb-2">
            {{item.name}}
        </p>
    </div>
    <div class="card__meta__overlay"></div>
</router-link>
</template>

<script>
export default {
    props: ['type', 'class', 'item']
}
</script>

<style scoped>
    .card{
        height: 0;
        padding-bottom: 100%;
    }

    .card.card_sm_height{
        padding-bottom: calc(50% - 12px);
    }

    .ratio_4x3{
        padding-bottom: 75%;
    }

    @media(max-width: 768px){
        .card.card_sm_height{
            height: 0;
            padding-bottom: 100%;
        }

        .card__meta{
            padding: 1.75rem;
        }
    }

    .card img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
    }

    .card:hover img{
        transform: translate(-50%, -50%) scale(1.05);
    }
</style>
