<template>
<div class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2">Выберите эстафеты, в которых участвует клуб</h2>
    </div>
    <div class="relay_form">
        <EstafetaField 
            v-for="(item, i) in items" 
            v-model="theModel[i]" 
            :key="i" 
            :item="item" />
    </div>
</div>
</template>

<script>
import EstafetaField from '@/components/forms/EstafetaField.vue'
import { computed } from 'vue';
export default {
    components: { EstafetaField },
    props: {
        items:{
            type: Array,
            default: []
        },
        modelValue:{
            type: Array,
            default: () => ({}),
        },
        price: {
            type: Number,
            default: 0
        }
    },
    methods:{
        setRelayRaces(){
            if(this.items){
                this.items.forEach(item => {
                    if(!this.theModel.find(elem => {
                        return elem.distance_id == item.id
                    })){
                        this.theModel.push({
                            distance_id: item.id,
                            quantity: 0,
                            price: this.price
                        })
                    }
                })
            }
        },
    },
    async mounted(){
        this.setRelayRaces()
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const theModel = computed({  
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });
        return {theModel};
    },
}
</script>

<style>

</style>
