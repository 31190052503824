<template>
<div>
    <EventView v-if="getEventPost.type == 'competition' " :postData="getEventPost" />
    <CampView v-if="getEventPost.type == 'camp'" :postData="getEventPost" />
    <MasterClassView v-if="getEventPost.type == 'master_class' " :postData="getEventPost" />
</div>
</template>

<script>
import EventView from './EventView.vue'
import MasterClassView from './MasterClassView'
import CampView from './CampView'
import setMeta from "@/views/seoMeta"
import {
    mapGetters, mapActions
} from "vuex";
export default {
    components: {
        MasterClassView,
        CampView,
        EventView, EventView
    },
    async mounted() {
        await this.fetchEvent(this.$route.params.id)
        setMeta(
            this.getEventPost.seo_title, 
            this.getEventPost.seo_description, 
            this.getEventPost.thumbnail,
            this.getEventPost.seo_robots
        )
    },
    methods: {
        ...mapActions({
            fetchEvent: "events/fetchEvent",
        }),
    },
    computed: {
        ...mapGetters({
            getEventPost: "events/getEventPost",
        }),
    }
}
</script>

<style>

</style>
