<template>
<div class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2 text-muted">Состав заказа</h2>
    </div>
    <div class="orders">
        <template v-if="distancesOrderList.length">
            <div class="orders__item" v-for="(type, i) in distancesOrderList" :key="i">
                <p class="product_name fw-bold mb-0">Заявочный взнос за дистанцию {{type.distance}}м {{type.count == 'many' ? 'со скидкой' : ''}}</p>
                <p class="price text-success fw-bold mb-0">{{type.quantity}} x {{type.price}} ₽</p>
            </div>
        </template>
        
        <div v-if="relaysOrderList.quantity" class="orders__item">
            <p class="product_name fw-bold mb-0">Заявочный взнос за эстафеты</p>
            <p class="price text-success fw-bold mb-0">{{relaysOrderList.quantity}} x {{relaysOrderList.price}} ₽</p>
        </div>
        <template v-for="(item, i) in extra_services" :key="i">
            <template v-if="item.quantity && item.quantity > 0">
                <OrderListServiceItem 
                    :item="item"
                />
            </template>
        </template>

    </div>
    <div v-if="calculateSumm" class="orders__summ d-flex justify-content-between text_20 fw-bold mt-4">
        <span>Итого: </span>
        <span class="text-success">{{calculateSumm}} ₽</span>
    </div>
    <div v-if="already_paid" class="orders__summ d-flex justify-content-between text_20 fw-bold mt-2">
        <span>Оплачено: </span>
        <span>{{already_paid}} ₽</span>
    </div>
    <div v-if="extraPay && extraPay > 0" class="orders__summ d-flex justify-content-between text_20 fw-bold mt-2">
        <span>К оплате: </span>
        <span class="text-success">{{extraPay}} ₽</span>
    </div>
    
</div>
</template>

<script>
import OrderListServiceItem from './OrderListServiceItem'
export default {
    props: {
        postdata: {
            type: Object,
            default: {},
        },
        distances: {
            type: Array,
            default: []
        },
        relay_races: {
            type: Array,
            default: []
        },
        extra_services: {
            type: Array,
            default: []
        },
        already_paid: {
            type: Number,
            default: null
        },
        entry_fee: {
            type: Number,
            default: null
        },
    },
    computed: {
        distancesOrderList(){
            let distances = [];
            this.distances.forEach(item => {
                distances.push(...item.distances)
            })

            return _.chain(distances)
                .groupBy(item => {
                    return item.distance +'-'+ item.count
                })
                .map(value => ({ distance: value[0].distance, count: value[0].count, quantity:value.length, price:value[0].price})).value()
        },
        relaysOrderList(){
            let count = 0
            this.relay_races.forEach(el => {
                if(el.quantity > 0){
                    count = count + el.quantity
                }
            })
            return {
                quantity: count,
                price: this.relay_races[0] ? this.relay_races[0].price : 0
            }
        },
        calculateSumm(){
            let summ = 0
            if(this.distancesOrderList.length > 0){
                this.distancesOrderList.forEach(item => {
                    summ += item.quantity * parseInt(item.price)
                })
            }
            summ += this.relaysOrderList.quantity * this.relaysOrderList.price
            if(this.extra_services.length > 0){
                this.extra_services.forEach(item => {
                    summ += item.quantity * parseInt(item.price)
                })
            }
            return summ
        },
        extraPay(){
            if(this.already_paid){
                return this.calculateSumm - this.already_paid
            }
            return null
        },
        
    },
    components: { OrderListServiceItem },
}
</script>

<style>

</style>
