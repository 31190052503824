<template>
<div class="page_title py-5">
    <div class="container">
        <div class="row flex-md-row-reverse">
            <div v-if="show_breadcrumbs" class="col-md-3 mb-5 mb-md-0 d-flex align-items-center justify-content-md-end">
                <nav v-if="breadcrumbs || title" style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23B9B9C3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E&#34;);" class="breadcrumbs" aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                            <router-link to="/">
                                <vue-feather type="home"></vue-feather>
                            </router-link>
                        </li>
                        <li v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumb-item">
                            <router-link :to="item.link">
                                {{item.title}}
                            </router-link>
                        </li>
                        <li v-if="title" class="breadcrumb-item active" aria-current="page">
                            {{title}}
                        </li>
                    </ol>
                </nav>
            </div>
            <div :class="show_breadcrumbs ? 'col-md-9' : 'col-md-12'">
                <div v-if="!is_mobile && multiple_titles && multiple_titles.length" class="d-flex align-items-end">
                    <template v-for="(item, i) in multiple_titles" :key="i">
                        <template v-if="item.title">
                            <h1 v-if="$route.name == item.route_name" class="section_title_medium lh-1">{{item.title}}</h1>
                            <router-link v-else :to="{name: item.route_name}">
                                <p class="section_title_medium fs-4 mb-2 lh-1">{{item.title}}</p>
                            </router-link>
                            <span v-if="i < multiple_titles.length - 1" class="seperator text-muted mx-3"></span>
                        </template>
                    </template>
                </div>
                <h1 v-if="is_mobile || (title && !multiple_titles)" class="section_title_medium">{{title}}</h1>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        breadcrumbs: {
            type: Array,
            default: null
        },
        show_breadcrumbs: {
            type: Boolean,
            default: true
        },
        multiple_titles: {
            type: Array,
            default: null
        }
    },
    computed: {
        is_mobile(){
            return window.innerWidth < 768
        }
    }
}
</script>

<style>

</style>
