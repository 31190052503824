<template>
  <div class="timeline_carousel__item">
        <div class="timeline_carousel__place">
            <p v-if="post.date" class="text-muted mb-0">{{post.date}}</p>
            <p v-if="post.city" class="fw-bold">{{post.city}}</p>
        </div>
        <div class="timeline_carousel__pin_cont">
            <router-link :to="'/stage/'+post.slug" class="timeline_carousel__pin d-block">
                <vue-feather type="link-2"></vue-feather>
            </router-link>
        </div>

        <router-link v-if="post.name" :to="'/stage/'+post.slug" class="timeline_carousel__title title_bold_24 d-block text-decoration-none mb-1">{{post.name}}</router-link>
        <p v-if="post.dates" class="mb-0 text-muted">{{post.dates}}</p>
    </div>
</template>

<script>
export default {
    props: ['post']
}
</script>

<style>

</style>