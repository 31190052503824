<template>
<auth-layout>
    <div class="row gx-0 min-vh-100">
        <div class="auth_background col-lg-8 d-none d-lg-block"></div>
        <div class="auth_content col-lg-4 d-flex align-items-center justify-content-center">
            <div class="auth_container d-flex flex-column align-items-center justify-content-center p-5 w-100">
                <router-link to="/">
                    <img class="auth_logo mb-3" src="/assets/img/logo.png" alt="Академия Спорта" />
                </router-link>
                <p class="section_title_medium mb-2 text-center">Восстановить пароль</p>
                <p class="text-muted text-center">Пожалуйста, укажите email учетной записи, на который будет выслана ссылка для восстановления пароля</p>
                <form @submit.prevent="restorePassword()" class="auth_form w-100">
                    <label class="mb-3 d-block">
                        <span class="fw-bold">Email учетной записи <span class="text-danger">*</span></span>
                        <input v-model="email" type="email" class="form-control" placeholder="example@gmail.com" autocomplete="on" required />
                    </label>

                    <button class="btn btn-primary btn-rounded w-100 mb-3" :disabled="loading">
                        <vue-feather v-if="loading" type="settings" animation="spin" animation-speed="slow"></vue-feather>
                        <span v-else>Отправить</span>
                    </button>

                    <div v-if="error_message" class="alert alert-danger text-danger" role="alert">{{error_message}}</div>
                </form>
                <div class="auth_other">
                    <router-link to="/auth" class="text-decoration-none">Назад</router-link>
                </div>
            </div>
        </div>
    </div>
</auth-layout>
</template>

<script>
import PasswordInput from '../../components/UI/PasswordInput.vue'
import AuthLayout from '@/layouts/AuthLayout.vue';
import axios from "axios"
export default {
    components: {
        AuthLayout,
        PasswordInput
    },
    data(){
        return {
            email: null,
            error_message: null,
            loading: false,
        }
    },
    methods: {
        async restorePassword(){
            this.loading = true
            axios.post(process.env.VUE_APP_BASE_URL+'forgot-password', {
                email: this.email,
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.error_message = null
                this.loading = false
                if(res.data.success){
                    this.$swal('Ссылка на изменение пароля отправлена на ваш email', '', 'success')
                } else {
                    this.error_message = res.data.status
                }
            }).catch(error => {
                this.loading = false
                this.error_message = error.response.data.message
            })
        }
    }
};
</script>

<style>
.auth_logo {
    max-width: 170px;
}
</style>
