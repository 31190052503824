<template>
    <span v-if="status === 'pending_payment'" class="badge bg-warning">В ожидании оплаты</span>
    <span v-if="status === 'sent'" class="badge bg-info">Принят</span>
    <span v-if="status === 'accepted'" class="badge bg-success">Выполнен</span>
    <span v-if="status === 'rejected'" class="badge bg-danger text-white">Отклонен</span>
</template>

<script>
export default {
    props: ['status'],
}
</script>

<style>

</style>