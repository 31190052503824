<template>
    <div class="pricetable_box" :class="item.special ? 'special' : ''">
        <div class="pricetable_box__header">
            <div class="pricetable_box__icon">
                <svg
                    v-if="item.special"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.8221 23.3357L20.6203 15.136C21.7936 13.557 22.4888 11.6025 22.4888 9.48857C22.4888 4.25654 18.2322 0 13.0002 0C7.76827 0 3.51173 4.25654 3.51173 9.48857C3.51173 11.6025 4.20687 13.557 5.38016 15.136L3.17841 23.3357C3.1032 23.6159 3.19369 23.9146 3.41185 24.1059C3.62995 24.2972 3.93773 24.3481 4.20595 24.2368L7.51106 22.866L9.68625 25.7018C9.83204 25.8919 10.0565 25.9999 10.2906 25.9999C10.3399 25.9999 10.3895 25.9952 10.439 25.9853C10.7234 25.9289 10.9511 25.7158 11.0263 25.4357L12.7613 18.9739C12.8407 18.9759 12.9204 18.977 13.0002 18.977C13.0801 18.977 13.1598 18.9759 13.2392 18.9739L14.9742 25.4357C15.0495 25.7158 15.2772 25.9289 15.5616 25.9853C15.611 25.9951 15.6607 25.9999 15.71 25.9999C15.944 25.9999 16.1685 25.8918 16.3143 25.7018L18.4895 22.866L21.7946 24.2368C22.0628 24.3481 22.3706 24.2972 22.5887 24.1059C22.8068 23.9146 22.8973 23.616 22.8221 23.3357V23.3357ZM11.2276 18.8113L9.95534 23.5496L8.36387 21.4748C8.21631 21.2824 7.99079 21.1766 7.75923 21.1766C7.66132 21.1766 7.5623 21.1955 7.46765 21.2348L5.05079 22.2372L6.59513 16.4856C6.5964 16.4858 6.59772 16.486 6.59899 16.4862C7.87222 17.6519 9.46343 18.4752 11.2257 18.8099C11.2263 18.8104 11.227 18.8109 11.2276 18.8113ZM5.03515 9.48857C5.03515 5.09655 8.60828 1.52342 13.0002 1.52342C17.3922 1.52342 20.9653 5.09655 20.9653 9.48857C20.9653 13.8805 17.3922 17.4537 13.0002 17.4537C8.60828 17.4537 5.03515 13.8805 5.03515 9.48857V9.48857ZM18.5329 21.2348C18.2142 21.1027 17.8466 21.2011 17.6366 21.4748L16.0452 23.5497L14.7728 18.8113C14.7732 18.8109 14.7735 18.8106 14.7738 18.8102C16.5365 18.4755 18.1281 17.6521 19.4016 16.4861C19.4028 16.4859 19.404 16.4858 19.4053 16.4856L20.9497 22.2372L18.5329 21.2348Z"
                    />
                    <path
                        d="M16.2145 10.5187L18.8477 8.31866C19.0864 8.11919 19.1799 7.7946 19.0837 7.49876C18.9876 7.20291 18.7212 6.99521 18.4108 6.97414L14.9875 6.74202L13.7088 3.55792C13.5929 3.26924 13.313 3.08008 13.0019 3.08008C12.6909 3.08008 12.411 3.26929 12.2951 3.55792L11.0164 6.74202L7.59304 6.97414C7.28267 6.99521 7.01628 7.20286 6.92015 7.49876C6.82397 7.7946 6.91746 8.11919 7.15618 8.31866L9.78931 10.5187L8.95219 13.8462C8.87632 14.1479 8.99144 14.4655 9.24316 14.6483C9.37626 14.7451 9.53342 14.7938 9.69089 14.7938C9.8312 14.7938 9.97171 14.7551 10.0959 14.6772L13.0019 12.8528L15.9079 14.6772C16.1714 14.8426 16.509 14.8312 16.7606 14.6483C17.0123 14.4655 17.1275 14.1479 17.0516 13.8462L16.2145 10.5187ZM14.6205 10.4266L15.1115 12.3784L13.407 11.3082C13.2831 11.2305 13.1426 11.1916 13.0019 11.1916C12.8614 11.1916 12.7207 11.2305 12.5969 11.3082L10.8924 12.3784L11.3834 10.4266C11.4547 10.143 11.3575 9.84365 11.1331 9.65622L9.58857 8.36578L11.5966 8.22964C11.8883 8.20984 12.143 8.02484 12.2519 7.75352L13.0019 5.88581L13.7519 7.75352C13.8609 8.02484 14.1156 8.20984 14.4072 8.22964L16.4153 8.36578L14.8708 9.65622C14.6464 9.84365 14.5492 10.143 14.6205 10.4266Z"
                    />
                </svg>
                <span v-else>{{num + 1}}</span>
            </div>
            <p class="title mb-1">
                {{ item.name }} {{ item.special ? " *" : "" }}
            </p>
            <p v-if="item.dates_str" class="subtitle fs-5 mb-0">
                {{ item.dates_str }}
            </p>
        </div>
        <div class="pricetable_box__content">
            <p v-if="status == 'open'" class="fw-bold text_20 text-success">
                Есть места
            </p>
            <p
                v-if="status == 'last_places'"
                class="fw-bold text_20 text-warning"
            >
                Осталось {{ item.tickets_count }} мест
            </p>
            <p
                v-if="status == 'end_places'"
                class="fw-bold text_20 text-danger"
            >
                Нет мест
            </p>
            <p v-if="status == 'end_event'" class="fw-bold text_20 text-danger">
                Смена завершена
            </p>
            <p v-if="status == 'closed'" class="fw-bold text_20 text-danger">
                Регистрация закрыта
            </p>
            <p v-if="status == 'end_places'" class="mb-0 text-danger">
                К сожалению, на эту смену больше нельзя зарегистрироваться
            </p>
            <p v-if="status == 'end_event'" class="mb-0">
                Смена уже закончилась, наблюдайте за анонсами на сайте и в
                соцсетях, чтобы не упустить открытие новых смен
            </p>
            <a
                v-if="status == 'open' || status == 'last_places' || status == 'closed'"
                @click.prevent="setOffcanvasData(offcanvasData), toggleOffcanvas('camp_shift')"
                class="btn btn-primary btn-primary-shadow btn-rounded"
                >{{status == 'closed' ? 'В лист ожидания' : 'Подать заявку'}}</a
            >
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {
    mapMutations
} from "vuex"
export default {
    props: ["item", "key", "eventId", 'num'],
    computed: {
        status() {
            if (this.item.open && this.item.tickets_count > 10) {
                return "open";
            }

            if (this.item.open && this.item.tickets_count <= 10) {
                return "last_places";
            }

            if (this.item.open && this.item.tickets_count <= 0) {
                return "end_places";
            }

            let now = moment();
            let date_end = moment(this.item.dates.end, "YYYY-MM-DD");
            if (now >= date_end) {
                return "end_event";
            }

            return "closed";
        },
        offcanvasData(){
            let item = this.item
            item.eventId = this.eventId
            return item 
        }
    },
    methods: {
        ...mapMutations({
            setOffcanvasData: "global/setOffcanvasData",
            toggleOffcanvas: "global/toggleOffcanvas",
        }),
    }
};
</script>

<style>
</style>