<template>
	<div class="list_box p-4">
		<div
			class="list_box__header d-flex justify-content-between align-items-center">
			<span class="fw-bold">{{ item.name }}</span>
			<div class="d-flex">
				<a
					@click.prevent="this.modals_visible = true"
					href="#"
					class="btn-circle text-muted me-1">
					<vue-feather type="edit"></vue-feather>
				</a>
				<SwalButton
					class="btn-circle text-muted"
					title="Вы действительно хотите удалить этот клуб?"
					text="После этого данные об этом клубе уже нельзя будет использовать в новых заявках"
					icon="warning"
					confirmButtonText="Удалить"
					confirmButtonColor="#EA5455"
					successText="Клуб удален"
					:callbackData="item.id"
					@callback="(id) => $emit('deleteClub', id)">
					<vue-feather type="trash-2"></vue-feather>
				</SwalButton>
			</div>
		</div>
		<table class="list_box__content">
			<tr v-if="item.short_name">
				<td class="text-muted">Короткое название:</td>
				<td class="ps-2">{{ item.short_name }}</td>
			</tr>
			<tr v-if="item.country">
				<td class="text-muted">Страна:</td>
				<td class="ps-2">{{ item.country }}</td>
			</tr>
			<tr v-if="item.city">
				<td class="text-muted">Город:</td>
				<td class="ps-2">{{ item.city }}</td>
			</tr>
			<tr v-if="item.state">
				<td class="text-muted">Код субъекта:</td>
				<td class="ps-2">{{ item.state }}</td>
			</tr>
			<tr v-if="item.agent_phone">
				<td class="text-muted">Телефон:</td>
				<td class="ps-2">{{ item.agent_phone }}</td>
			</tr>
			<tr v-if="item.agent_email">
				<td class="text-muted">Email:</td>
				<td class="ps-2">{{ item.agent_email }}</td>
			</tr>
		</table>
	</div>
	<AddClub
		:selected_club="item"
		@changeData="$emit('changeClub')"
		:visible="modals_visible"
		@hide="modals_visible = false" />
</template>

<script>
import AddClub from "../offcanvases/AddClub.vue"
import SwalButton from "../SwalButton.vue"
export default {
	data() {
		return {
			modals_visible: false,
		}
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	emits: ["changeClub", "deleteClub"],
	components: {
		SwalButton,
		AddClub,
	},
}
</script>

<style scoped>
.list_box {
	height: 100%;
}
</style>