<template>
	<div class="inputgroup_distance col-12 row g-2" :class="'key_' + index">
		<div class="col-md-6">
			<label class="d-block">
				<span class="fw-bold">Выберите дистанцию <span class="text-danger">*</span></span>
				<select
					v-model="theModel.id"
					@change="$emit('changeDistance', index)"
					class="form-select"
					:class="show_errors && !theModel.id ? 'invalid' : ''">
					<option :value="null" disabled>Выберите вариант</option>
					<option
						v-for="(dist, dist_i) in allowedDistances"
						:key="dist_i"
						:value="dist.id"
						:disabled="
							dist.disabled || used_distances.includes(dist.id) ? true : false
						">
						{{ `${dist.distance}м, ${dist.stroke_name}, ${dist.age_name}` }}
					</option>
				</select>
				<p v-if="show_errors && !theModel.id" class="mb-0 small text-danger">
					Обязательное поле
				</p>
			</label>
		</div>
		<div v-if="theModel.id" class="col-md-5">
			<label for="athlet_time" class="d-block">
				<span class="fw-bold">Заявочное время</span>
			</label>
			<div class="d-flex align-items-center">
				<input
					v-model="theModel.entrytime"
					type="text"
					class="form-control me-3"
					placeholder="мм:сс.мс"
					v-maska="'##:##.##'"
					:class="{
						invalid: show_errors && !theModel.system_entrytime && !theModel.entrytime,
					}" />
				<!-- <span v-if="theModel.system_entrytime" class="text-muted system_entrytime_text small">
					Заявочное время нельзя изменить, так как использованы результаты из базы данных
				</span> -->
			</div>
			<p
				v-if="show_errors && !theModel.system_entrytime && !theModel.entrytime"
				class="mb-0 small text-danger">
				Обязательное поле
			</p>
		</div>
		<div v-if="delete_btn" class="col-auto d-flex align-items-end pb-1">
			<button
				@click.prevent="$emit('removeDistance', index)"
				class="btn btn-circle bg-white text-muted ms-auto mb-2">
				<vue-feather type="trash-2" />
			</button>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
export default {
	props: {
		allowedDistances: {
			type: Array,
			default: [],
		},
		used_distances: {
			type: Array,
			default: [],
		},
		index: {
			type: Number,
			required: true,
		},
		delete_btn: {
			type: Boolean,
			default: false,
		},
		show_errors: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		return { theModel }
	},
	emits: ['changeDistance', 'removeDistance', 'update:modelValue'],
}
</script>

<style>
.system_entrytime_text{
	font-size: 12px;
}
</style>