<template>
    <main>
      <slot/>
    </main>
</template>

<script>
export default {
  components: {},
    name: 'AuthLayout'
}
</script>

<style>
</style>