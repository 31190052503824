<template>
	<footer :style="'padding-top:' + FOOTER_PADDING_TOP + 'px; margin-top: -' + (FOOTER_PADDING_TOP - 30) + 'px;'">
		<div class="container">
			<div class="footer_top row mt-4 mb-5">
				<div class="col-lg-9">
					<FooterNav />
				</div>
				<div class="col-lg-3">
					<SocialLinks />
				</div>
			</div>
			<div class="footer_bottom row py-4">
				<div class="col">
					<router-link to="/page/8">Политика конфиденциальности</router-link>
					<!-- <router-link to="/page/1">Правила и условия сервиса</router-link> -->
				</div>
				<div class="col-auto">
					<p class="text-md-end mt-4 mt-md-0">© 2014-{{ year }} <!-- ЗАО "Академия Спорта" --></p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import SocialLinks from '../SocialLinks'
import FooterNav from './FooterNav'
export default {
	components: {
		SocialLinks,
		FooterNav,
	},
	computed: {
		FOOTER_PADDING_TOP(){
			return this.$store.getters['global/getFooterTopPadding']
		},
		year() {
			return new Date().getFullYear()
		},
		is_subscribed() {
			if (localStorage.getItem('is_subscribed')) {
				return localStorage.getItem('is_subscribed')
			} else {
				if (this.userData) {
					return this.userData.is_subscribed
				}
			}
			return
		},
		userData() {
			return this.$store.getters['profile/getUserData']
		},
		showPadding(){
			if(!this.is_subscribed && !this.$route.fullPath.includes('shop')) return true
			return false
		}
	},
}
</script>

<style>
footer.footer__without_subscribe_section {
	padding-top: 30px;
	margin-top: 0;
}
</style>
