<template>
	<div v-if="ATHLETES" class="content_box p-4 mb-5">
		<div
			class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
			<h2 class="title_block mb-0">Cпортсмены</h2>
			<a
				@click.prevent="modal_is_open = true"
				href="#"
				class="btn btn-primary btn-rounded btn-primary-shadow">
				<vue-feather class="d-xs-none" type="plus-circle"></vue-feather>Добавить
			</a>
		</div>
		<DataTable no_content_text="Еще нет добавленных спортсменов">
			<template #toolbox>
				<div class="table_toolbox__item d-flex fw align-items-center mb-3">
					<span class="small text-muted me-2 d-none d-md-block">Показать</span>
					<select v-model="pageSize" class="form-select">
						<option :value="10">10</option>
						<option :value="20">20</option>
						<option :value="50">50</option>
						<option :value="100">100</option>
					</select>
				</div>
				<div class="table_toolbox__item table_toolbox__item__search mb-3">
					<input
						v-model="filter.name"
						type="text"
						class="form-control"
						placeholder="Поиск..." />
					<vue-feather v-if="filter.name" @click="clearFilterName" type="x" size="16" />
				</div>
			</template>
			<template #head>
				<tr>
					<th scope="col fw-bold">
						<SortTrigger
							@click="sort('name')"
							:active="currentSort === 'name' ? true : false"
							name="Фамилия Имя"
							:order="currentSortDir" />
					</th>
					<th scope="col fw-bold">
						<SortTrigger
							@click="sort('category')"
							:active="currentSort === 'category' ? true : false"
							name="Разряд"
							:order="currentSortDir" />
					</th>
					<th scope="col fw-bold">
						<SortTrigger
							@click="sort('birthdate')"
							:active="currentSort === 'birthdate' ? true : false"
							name="Дата рождения"
							:order="currentSortDir" />
					</th>
					<th scope="col fw-bold">
						<SortTrigger
							@click="sort('nation')"
							:active="currentSort === 'nation' ? true : false"
							name="Гражданство"
							:order="currentSortDir" />
					</th>
					<th scope="col fw-bold"></th>
				</tr>
			</template>
			<template #default>
				<TableAthleteRow
					v-for="(item, i) in sortedPaginatedRows"
					:key="i"
					:item="item"
					@deleteAthlete="deleteAthlete" />
			</template>
		</DataTable>

		<PaginationBox
			class="m-4 pb-4"
			:currentPage="currentPage"
			:pageSize="pageSize"
			:itemsLength="sortedRows.length"
			v-on:changeCurrentPage="changeCurrentPage" />
	</div>
	<EditAthlet :visible="modal_is_open" @hide="modal_is_open = false" />
</template>

<script>
import EditAthlet from '../offcanvases/EditAthlet.vue'
import TableAthleteRow from '../table/TableAthleteRow.vue'
import SortTrigger from '../table/SortTrigger.vue'
import Nation from '../UI/Nation'
import Avatar from '../UI/Avatar'
import DataTable from '../table/DataTable.vue'
import PaginationBox from '../PaginationBox'
import SwalButton from '../../components/SwalButton.vue'
import { deleteAthleteAPI } from '@/API/api'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	components: {
		Nation,
		Avatar,
		PaginationBox,
		CDropdown,
		CDropdownToggle,
		CDropdownMenu,
		CDropdownItem,
		SwalButton,
		DataTable,
		SortTrigger,
		TableAthleteRow,
		EditAthlet,
	},
	data() {
		return {
			currentSort: 'name',
			currentSortDir: 'desc',
			pageSize: 20,
			currentPage: 1,
			is_visible: 0,
			filter: {
				name: '',
			},
			error_message: '',
			modal_is_open: false,
		}
	},
	async mounted() {
		await this.fetchAthletes()
	},
	methods: {
		...mapActions({
			fetchAthletes: 'profile/fetchAthletes',
		}),
		sort(s) {
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
			}
			this.currentSort = s
		},
		changeCurrentPage(page) {
			this.currentPage = page
			return true
		},
		clearFilterName() {
			this.filter.name = ''
		},
		deleteAthlete(id) {
			deleteAthleteAPI(id)
		},
	},
	computed: {
		...mapGetters({
			ATHLETES: 'profile/getAthletes',
		}),
		sortedRows: function () {
			return this.ATHLETES.sort((a, b) => {
				let modifier = 1
				if (this.currentSortDir === 'desc') modifier = -1
				if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
				if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier
				return 0
			}).filter((row, index) => {
				if (this.filter.name.length < 3) return true
				return row.name.toLowerCase().includes(this.filter.name.toLowerCase())
			})
		},
		sortedPaginatedRows: function () {
			return this.sortedRows.filter((row, index) => {
				let start = (this.currentPage - 1) * this.pageSize
				let end = this.currentPage * this.pageSize
				if (index >= start && index < end) return true
			})
		},
	},
	emits: ['changeData'],
}
</script>

<style scoped>
.dropdown-menu {
	min-width: 150px;
}

.dropdown-toggle:after,
.dropdown-toggle:before {
	display: none;
}

.dropdown-menu a {
	text-decoration: none;
	color: #071031;
}
</style>