<template>
<OffcanvasInline @hide="$emit('hide'), visible = !visible" :visible="visible">
<p class="section_title_medium mb-3">{{item.name}}</p>
<p class="text-muted mb-3">{{item.description}}</p>

<p class="fw-bold mb-1">Выберите количество:</p>
<div v-if="theModel" class="input-group input-group-num mb-3">
    <button @click.prevent="theModel.quantity = theModel.quantity - 1" :disabled="theModel.quantity < 1" type="button">-</button>
    <input v-model="theModel.quantity" @change="checkMinMax" type="number" class="form-control" min="1" step="1"  >
    <button @click.prevent="theModel.quantity = theModel.quantity + 1" type="button">+</button>
</div>

<button @click="$emit('hide')" class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">Сохранить</button>

</OffcanvasInline>
</template>

<script>
import OffcanvasInline from './OffcanvasInline.vue'
import { computed } from 'vue';
export default {
    props: {
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        visible: {
            type: Boolean
        },
        item: {
            type: Object,
            default: {}
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const theModel = computed({  
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });
        return { theModel };
    },
    methods: {
        checkMinMax(e){
            if(e.target.value < 0){
                this.theModel.quantity = 0
            }
        }
    },
    components: {
        OffcanvasInline
    },
}
</script>

<style>
</style>
