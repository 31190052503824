<template>
    <div v-if="setup_complete" class="expand_cont p-3 border rounded mb-2">
        <div>
            <Avatar :src="athlete.avatar" :name="athlete.name" :class="'me-2'"/>
            <span class="fw-bold">{{athlete.name}}</span>
        </div>
        <div v-if="distances && distances.length > 0" class="mt-3">
            <p class="text-muted mb-0">Для дистанций</p>
            <p v-for="(distance, i) in distances" :key="i" class="mb-0">
                {{distance.distance}}, {{distance.stroke_name}}, {{distance.age_name}}
            </p>
        </div>
    </div>
</template>

<script>
import Avatar from '../UI/Avatar'
import { mapGetters } from "vuex"
export default {
    data(){
        return {
            athlete: null,
            distances: [],
            setup_complete: false
        }
    },
    async mounted(){
        this.athlete = await this.getAthleteByID(this.item.athlete_id)
        await this.setDistanceNames()
        this.setup_complete = true
    },
    computed: {
        ...mapGetters({
            getAthleteByID: "athlets/getAthleteByID",
            getDistance: "events/getStageDistanceByID",
        }),
    },
    methods: {
        async setDistanceNames(){
            this.item.distances.forEach(id => {
                let distance = this.getDistance(id)
                if(distance) this.distances.push(distance)
            })
        }
    },
    props: ['item'],
    components: { Avatar },
}
</script>

<style scoped>
    .expand_cont:last-child{
        margin-bottom: 0 !important;
    }
</style>