<template>
<div v-if="uploaded" class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2 text-muted">Информация о заказе</h2>
    </div>
    <div class="order_info row g-3 mb-3">
        <div class="col-auto small">
            <p class="text-muted mb-1">ID заказа:</p>
            <p class="mb-0">#{{id}}</p>
        </div>
        <div class="col-auto small">
            <p class="text-muted mb-1">Сумма:</p>
            <p class="mb-0">{{summ}} ₽</p>
        </div>
        <div class="col-auto small">
            <p class="text-muted mb-1">Статус оплаты:</p>
            <span v-if="payment_status == 'paid'" class="badge text-white text-decorative text-uppercase bg-success">Оплачен</span>
            <span v-if="payment_status == 'rejected'" class="badge text-white text-decorative text-uppercase bg-danger">Отклонен</span>
            <span v-if="payment_status != 'rejected' && payment_status != 'paid'" class="badge text-dark text-decorative text-uppercase bg-warning">В ожидании оплаты</span>
        </div>
        <div v-if="payment_status != 'paid' && payment_status != 'rejected'" class="col-12">
            <p class="text-muted mb-1 small">К оплате: </p>
            <p class="text_20 fw-bold mb-0">{{summ}} ₽</p>
        </div>
        <div v-if="this.paytime_end && payment_status != 'paid' && payment_status != 'rejected'" class="col-12 text-warning">
            <p class="mb-1">Пожалуйста, оплатите заказ в течение</p>
            
            <VueCountdown :time="end_timer" :transform="transformSlotProps" v-slot="{ days, hours, minutes, seconds }" @end="onCountdownEnd">
                <p class="counter fw-bold mb-1">
                    <span v-if="days != '00'">{{days}} дн. </span>  
                    <span v-if="hours != '00'">{{hours}} час. </span>  
                    <span>{{minutes}} мин. </span>  
                    <span>{{seconds}} сек.</span></p>
            </VueCountdown>
            <p class="mb-1">В противном случае заявка будет аннулирована</p>
        </div>
    </div>
    <button v-if="payment_status != 'paid' && payment_status != 'rejected'" @click.prevent="$emit('send')" class="btn btn-primary btn-primary-shadow btn-rounded">Перейти к оплате</button>
</div>
</template>

<script>
import moment from 'moment-timezone'
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
    props: ['id', 'payment_status', 'summ', 'paytime_end'],
    data() {
        return {
            end_timer: null,
            uploaded: false
        }
    },
    async mounted(){
        await this.setTimer()
        this.uploaded = true
    },
    methods: {
        transformSlotProps(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
        onCountdownEnd() {
            this.$emit('time_end')
        },
        async setTimer(){
            if(this.paytime_end && this.payment_status == 'pending'){         
                let paytime_end = moment(this.paytime_end)     
                let current_date = moment()
                this.end_timer = paytime_end - current_date
                if(this.end_timer < 0) this.onCountdownEnd()
            }
        }
    },
    emits: ['time_end', 'send'],
    components: {VueCountdown},
}
</script>

<style>

</style>
