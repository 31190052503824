<template>
<router-link :to="'/news/'+post.slug" class="card" :class="this.class">
    <img :src="post.thumbnail" class="card__img" />
    <div v-if="post.date" class="card__date small">
        <vue-feather type="calendar"></vue-feather> {{post.date}}
    </div>
    <div class="card__meta">
        <span v-if="post.category" class="badge bg-warning text-dark card__category mb-2">{{post.category}}</span>
        <p v-if="post.name" class="card_title_medium d-block text-decoration-none text-light mb-0">
            {{post.name}}
        </p>
    </div>
    <div class="card__meta__overlay"></div>
</router-link>
</template>

<script>
export default {
    props: ['class', 'post']
}
</script>

<style scoped>
    .card{
        height: 0;
        padding-bottom: 100%;
    }

    .card.card_sm_height{
        padding-bottom: calc(50% - 12px);
    }

    @media(max-width: 768px){
        .card.card_sm_height{
            height: 0;
            padding-bottom: 100%;
        }

        .card__meta{
            padding: 1.75rem;
        }
    }

    .card img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
    }

    .card:hover img{
        transform: translate(-50%, -50%) scale(1.05);
    }

</style>
