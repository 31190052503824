<template>
    <span v-if="status === 'draft'" class="badge bg-secondary">Черновик</span>
    <span v-if="status === 'sent'" class="badge bg-info">Отправлен</span>
    <span v-if="status === 'accepted'" class="badge bg-success">Принят</span>
    <span v-if="status === 'pending_payment'" class="badge bg-warning">В ожидании оплаты</span>
    <span v-if="status === 'сond_admitted'" class="badge bg-success">Условно допущен</span>
    <span v-if="status === 'admitted'" class="badge bg-success">Допущен</span>
    <span v-if="status === 'rejected'" class="badge bg-danger text-white">Отклонен</span>
</template>

<script>
export default {
    props: ['status'],
}
</script>

<style>

</style>