<template>
    <div class="event_results_links content_box p-4 mb-3">
        <div class="row g-4">
            <div class="col-lg-2 d-flex align-items-center">
                <p class="title_block text-muted mb-0">{{item.date}}</p>
            </div>
            <div class="col-lg-4 d-flex align-items-center">
                <router-link :to="{ name: 'stage', params: { id: item.id }}" class="me-2 flex-shrink-0">
                    <img v-if="item.icon"
                        :src="item.icon"
                        class="championship__logo"
                    />
                </router-link>
                <div class="fw-bold">
                    <p class="text_20 mb-1">{{item.name}}</p>
                    <p class="text-muted mb-0">{{item.city}}</p>
                </div>
            </div>
            <div class="col-lg-6 d-flex justify-content-lg-end align-items-center">
                <div class="row g-2">
                    <div v-if="item.final_protocol_link" class="col-md-auto">
                        <a
                            :href="item.final_protocol_link"
                            target="_blank"
                            class="btn btn-outline btn-outline-secondary btn-rounded btn-sm w-100 w-md-auto"
                        >
                            <vue-feather
                                type="file-text"
                                class="text-primary"
                            />
                            Итоговый протокол
                        </a>
                    </div>
                    <div v-if="item.result_link" class="col-md-auto">
                        <a
                            :href="item.result_link"
                            target="_blank"
                            class="btn btn-outline btn-outline-secondary btn-rounded btn-sm w-100 w-md-auto"
                        >
                            <vue-feather type="link" class="text-primary" />
                            Результаты
                        </a>
                    </div>
                    <div v-if="item.lenex_file" class="col-md-auto">
                        <a
                            :href="item.lenex_file"
                            target="_blank"
                            class="
                                btn
                                btn-outline
                                btn-outline-secondary
                                btn-rounded
                                btn-sm
                                w-100 w-md-auto
                            "
                        >
                            <vue-feather type="file" class="text-primary" />
                            Файл-Lenex
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item']
};
</script>

<style>
</style>