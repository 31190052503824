<template>
    <h1 class="section_title_medium mb-4">Командные рейтинги</h1>
	<div class="content_box">
		<FilterWrap class="p-sm-5 filter_box__br0">
			<template #default>
				<FilterSelect
					label="Бассейн"
					v-model="filter.pool"
					:className="'col-12 col-md-4 col-lg-2'">
					<option value="0">Все</option>
					<option v-for="(item, i) in filter_data.pools" :key="i" :value="item">
						{{ poolName(item) }}
					</option>
				</FilterSelect>
				<FilterSelect
					label="Дистанция"
					v-model="filter.distance"
					:className="'col-12 col-md-4 col-lg-2'">
					<option value="0">Все</option>
					<option v-for="(item, i) in filter_data.distances" :key="i" :value="item">
						{{ item }}
					</option>
				</FilterSelect>
				<FilterSelect
					label="Стиль"
					v-model="filter.stroke"
					:className="'col-12 col-md-4 col-lg-2'">
					<option value="0">Все</option>
					<option v-for="item in filter_data.strokes" :key="item.key" :value="item.key">
						{{ item.name }}
					</option>
				</FilterSelect>
				<FilterClear @clicked="clearFilter()" />
                <div class="col-auto d-flex align-items-end">
                    <button @click="fetchResults(true)" class="btn btn-sm btn-rounded btn-warning btn-shadow w-100 text-uppercase mb-1 px-4">Показать</button>
                </div>
			</template>
			<template #expand>
				<FilterInput
					label="Возраст от"
					v-model="filter.age_start"
					:className="'col-12 col-md-4 col-lg-3'"
					placeholder="Введите число"
					type="number">
				</FilterInput>
				<FilterInput
					label="Возраст до"
					v-model="filter.age_end"
					:className="'col-12 col-md-4 col-lg-3'"
					placeholder="Введите число"
					type="number">
				</FilterInput>
				<FilterSelect
					label="Год"
					v-model="filter.year"
					:className="'col-12 col-md-4 col-lg-3'">
					<option value="0">Все</option>
					<option v-for="(item, i) in filter_data.years" :key="i" :value="item">
						{{ item }}
					</option>
				</FilterSelect>
				<FilterSelect
					label="Субъект"
					v-model="filter.state"
					:className="'col-12 col-md-4 col-lg-3'">
					<option value="0">Все</option>
					<option value="KAZ">KAZ</option>
					<option value="BEL">BEL</option>
					<option v-for="(item, i) in filter_data.states" :key="i" :value="item">
						{{ item }}
					</option>
				</FilterSelect>
				<FilterDateInput
					label="Период от"
					v-model="filter.date_start"
					:className="'col-12 col-md-4 col-lg-3'" />
				<FilterDateInput
					label="Период до"
					v-model="filter.date_end"
					:className="'col-12 col-md-4 col-lg-3'" />
				<FilterInput
					label="Поиск по названию"
					v-model="filter.club_name"
					:className="'col-12 col-md-5'" />
				<FilterCheckbox
					label="Только один результат для клуба"
					v-model="filter.one_result_club"
					:className="'col-12 col-md-3'" />
			</template>
		</FilterWrap>
		<DataTable class="m-0">
			<template #head>
				<tr>
					<th scope="col" class="fw-bold small">
						<SortTrigger
							@click="sort('swimtime')"
							:active="filter.sort_by === 'swimtime' ? true : false"
							name="Место"
							:order="filter.sort_dir" />
					</th>
					<th scope="col" class="fw-bold small">
						<SortTrigger
							@click="sort('athlete_name')"
							:active="filter.sort_by === 'club_name' ? true : false"
							name="Команда"
							:order="filter.sort_dir" />
					</th>
					<th scope="col" class="fw-bold small">
						<SortTrigger
							@click="sort('club_nation')"
							:active="filter.sort_by === 'club_nation' ? true : false"
							name="Cубъект"
							:order="filter.sort_dir" />
					</th>
					<th scope="col" class="fw-bold small">
						<SortTrigger
							@click="sort('swimtime')"
							:active="filter.sort_by === 'swimtime' ? true : false"
							name="Время"
							:order="filter.sort_dir" />
					</th>
					<th scope="col" class="fw-bold small">
						<SortTrigger
							@click="sort('meet_name')"
							:active="filter.sort_by === 'meet_name' ? true : false"
							name="Соревнование"
							:order="filter.sort_dir" />
					</th>
					<th scope="col" class="fw-bold small">
						<SortTrigger
							@click="sort('city')"
							:active="filter.sort_by === 'city' ? true : false"
							name="Город"
							:order="filter.sort_dir" />
					</th>
					<th scope="col" class="fw-bold small">
						<SortTrigger
							@click="sort('session_date')"
							:active="filter.sort_by === 'session_date' ? true : false"
							name="Дата"
							:order="filter.sort_dir" />
					</th>
				</tr>
			</template>
			<template #default>
				<tr v-for="(item, i) in table_data" :key="i">
					<td class="align-middle">{{ item.place }}</td>
					<td class="align-middle fw-bold">{{ item.club_name }}</td>
					<td class="align-middle">
						<GeoSubject :name="item.club_code ? item.club_code : item.club_nation" />
					</td>
					<td class="align-middle">{{ item.swimtime.slice(3) }}</td>
					<td class="align-middle">{{ item.meet_name }}</td>
					<td class="align-middle">{{ item.city }}</td>
					<td class="align-middle">{{ item.session_date }}</td>
				</tr>
			</template>
		</DataTable>
		<PaginationBox
			class="m-4 pb-4"
			:currentPage="filter.page"
			:pageSize="filter.page_limit"
			:itemsLength="total"
			@changepage="changepage" />
	</div>
</template>

<script>
import GeoSubject from '../components/UI/GeoSubject.vue'
import Nation from '../components/UI/Nation.vue'
import SortTrigger from '../components/table/SortTrigger.vue'
import FilterCheckbox from '../components/filter/FilterCheckbox'
import FilterInput from '../components/filter/FilterInput'
import FilterDateInput from '../components/filter/FilterDateInput'
import FilterAdvancedSelect from '../components/filter/FilterAdvancedSelect'
import FilterClear from '../components/filter/FilterClear.vue'
import FilterSelect from '../components/filter/FilterSelect.vue'
import FilterWrap from '../components/filter/FilterWrap.vue'
import PaginationBox from '../components/PaginationBox.vue'
import Avatar from '../components/UI/Avatar'
import DataTable from '../components/table/DataTable'
import VSelect from "vue-select";
import {strokes} from '@/helpers/fieldNames'
import axios from "axios"

export default {
    data() {
        return {
            is_expanded: false,
            table_data: [],
            total: 0,
            filter: {
                type: 'relay',
                page_limit: 20,
                page: 1,
                pool: 0,
                distance: 0,
                stroke: 0,
                year: 0,
                state: 0,
				age_start: 0,
				age_end: 0,
                date_start: 0,
                date_end: 0,
                club_name: null,
                one_result_club: null,
                sort_by: "swimtime",
                sort_dir: "ASC",
            },
            filter_data: {
                pools: ['SCM', 'LCM'],
                distances: [],
                strokes: [],
                years: [],
                states: [],
            },
        }
    },
    watch: {
        filterData: {
            handler(){
                this.setFilterData()
            },
            deep: true
        }
    },
    async mounted(){
        await this.$store.dispatch("global/fetchFilterResultIndex")
        await this.fetchResults()
    },
    methods: {
        async fetchResults(reset_page = false){
			if(reset_page){
				this.filter.page = 1
			}
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL+'results/ratings/filter', {params: this.filter})
                this.total = response.data.total
                this.table_data = response.data.items
            } catch (e){
                if(e.response.status === 404){
                    router.push({name: 'not_found'})
                } else {
                    alert("Ошибка получения данных с сервера о результатах")
                }
            }
            this.setPlaceNumberOnItems()
        },
        setPlaceNumberOnItems(){
            let number_base = this.filter.page * this.filter.page_limit - this.filter.page_limit + 1
            if(this.filter.page == 1){
                number_base = 1
            }
            this.table_data = this.table_data.map((item, key) => ({
                ...item, place: number_base + key
            }))
        },
        sort(s) {
            if (s === this.filter.sort_by) {
                this.filter.sort_dir =
                    this.filter.sort_dir === "ASC" ? "DESC" : "ASC";
            }
            this.filter.sort_by = s;
            this.fetchResults()
        },
        changepage(page){
            this.filter.page = page
            this.fetchResults()
        },
        setFilterData(){
            this.filter_data.distances = this.filterData.relays.distances
            this.filter_data.strokes = this.filterData.relays.strokes
            this.filter_data.years = this.filterData.relays.years
            this.filter_data.states = this.filterData.relays.states
            this.filter_data.strokes.sort((a, b) => {
                if(strokes.indexOf(a.name) < 0){
                    return strokes.indexOf(b.name) - strokes.indexOf(a.name)
                }
                return strokes.indexOf(a.name) - strokes.indexOf(b.name)
            })
        },
        clearFilter(){
            this.filter = {
                type: 'relay',
                page_limit: this.filter.page_limit,
                page: this.filter.page,
                pool: 0,
                distance: 0,
                stroke: 0,
                year: 0,
                state: 0,
                date_start: 0,
                date_end: 0,
                club_name: null,
                one_result_club: null,
                sort_by: "swimtime",
                sort_dir: "ASC",
            }
            this.fetchResults(true)
        },
        poolName(course){
            if(course == 'SCM') return '25 метров'
            if(course == 'LCM') return '50 метров'
        },
    },
    computed:{
        filterData(){
            return this.$store.getters["global/getFilterResultsIndex"]
        }
    },
    components: {
        FilterCheckbox,
        FilterInput,
        FilterDateInput,
        FilterAdvancedSelect,
        Avatar,
        DataTable,
        VSelect, 
        PaginationBox, 
        FilterWrap, 
        FilterSelect, 
        FilterClear, 
        SortTrigger, 
        Nation,
        GeoSubject
    },
}
</script>

<style></style>
