<template>
    <p class="text-decorative text-uppercase text-warning fs-3 fw-bold mb-1">
        Заявка сформирована
    </p>
    <p class="fw-bold">
        Пожалуйста, внимательно проверьте данные перед отправкой.<br />
        После отправки заявки вы будете перенаправлены на страницу оплаты.
    </p>
    <div class="row g-3 mb-5">
        <div class="col-md-6">
            <OrdersList 
                :entry_fee="formdata.entry_fee"
                :distances="formdata.distances"
                :relay_races="formdata.relay_races"
                :extra_services="formdata.extra_services" 
                :postdata="postdata" 
                :already_paid="already_paid"
            />
        </div>

        <div class="col-md-6">
            <FormPayerFieldgroup 
                v-model:payers_name="payers_name"
                v-model:payers_phone="payers_phone"
                v-model:payers_email="payers_email"
                v-model:has_errors="has_errors"
                :show_errors="show_errors"
            />
            <FormPaymentMethods 
                v-if="postdata.first_status == 'pending_payment' && !hide_payment_methods"
                v-model="payment_method"
                :accept_methods="[1]"
                :send_order_btn="true"
                :has_errors="hasErrors()"
                @sendOrder="$emit('sendOrder')"
            />
        </div>
    </div>

    <div class="content_box p-4 mb-4">
        <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2" >
            <h2 class="title_block mb-2">Данные о мероприятии</h2>
        </div>
        <div class="row g-4">
            <div v-if="postdata.name && postdata.id" class="col-md-4 small">
                <p class="text-muted mb-1">Мероприятие</p>
                <router-link :to="`/stage/${postdata.id}`" class="mb-0 fw-bold">
                    {{postdata.name}}
                </router-link>
            </div>
            <div v-if="postdata.location_address" class="col-md-4 small">
                <p class="text-muted mb-1">Место проведения</p>
                <p class="mb-0">
                    {{postdata.location_address}}
                </p>
            </div>
            <div v-if="postdata.dates" class="col-md-4 small">
                <p class="text-muted mb-1">Сроки проведения</p>
                <p class="mb-0">
                    {{postdata.dates}}
                </p>
            </div>
        </div>
    </div>

    <SportsmensTable 
        :items="formdata.distances"
        :distances="true"
        :documents="true"
        :short_swimtime="false"
    />
    <EstafetasTable 
        v-if="formdata.relay_races"
        :items="formdata.relay_races"
    />
    <AdditionalData 
        :club="{
            name: formdata.club_name,
            shortname: formdata.club_shortname,
            state: formdata.club_state,
        }"
        :contacts="{
            name: formdata.agent_name,
            phone: formdata.agent_phone,
            email: formdata.agent_email,
        }"
    />
</template>

<script>
import FormPaymentMethods from '../../components/forms/FormPaymentMethods'
import FormPayerFieldgroup from './FormPayerFieldgroup'
import AdditionalData from '../../components/request/AdditionalData'
import EstafetasTable from '../../components/request/EstafetasTable'
import SportsmensTable from '../../components/request/SportsmensTable'
import OrdersList from '../../components/request/OrdersList'
import { computed } from 'vue';

export default {
    props: {
        formdata: {
            type: Object,
            required: true
        },
        postdata: {
            type: Object,
            required: true
        },
        payers_name: {
            type: String,
            default: () => ({}),
        },
        payers_phone: {
            type: String,
            default: () => ({}),
        },
        payers_email: {
            type: String,
            default: () => ({}),
        },
        payment_method: {
            type: Number,
            default: 1,
        },
        has_errors: {
            type: Boolean,
            default: () => (false),
        },
        show_errors: {
            type: Boolean,
            default: () => (false),
        },
        already_paid: {
            type: Number,
            default: null,
        },
        hide_payment_methods: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            order_summ: 0,
        }
    },
    methods:{
        hasErrors(){
            if(!this.payers_name || !this.payers_phone || !this.payers_email){
                return true
            } else {
                return false
            }
        },
    },
    setup(props, { emit }) {
        const payers_name = computed({  
            get: () => props.payers_name,
            set: (value) => emit('update:payers_name', value),
        });
        const payers_phone = computed({  
            get: () => props.payers_phone,
            set: (value) => emit('update:payers_phone', value),
        });
        const payers_email = computed({  
            get: () => props.payers_email,
            set: (value) => emit('update:payers_email', value),
        });
        const payment_method = computed({  
            get: () => props.payment_method,
            set: (value) => emit('update:payment_method', value),
        });
        const has_errors = computed({  
            get: () => props.has_errors,
            set: (value) => emit('update:has_errors', value),
        });
        return { has_errors, payers_name, payers_phone, payers_email, payment_method };
    },
    emits: ['update:has_errors','update:payers_name','update:payers_phone','update:payers_email','update:payment_method', 'sendOrder'],
    components: {
    FormPaymentMethods,
    FormPayerFieldgroup,
        AdditionalData,
        EstafetasTable,
        SportsmensTable, 
        OrdersList 
    },
};
</script>

<style>
</style>