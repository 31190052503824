<template>
<div class="media_box content_box">
    <div class="d-flex justify-content-between align-items-center">
        <p class="media_box__title">{{title}}</p>
        <div class="carousel-nav mb-3">
            <button @click="swiperPrev()" class="carousel-control-prev me-3">
                <vue-feather type="arrow-left-circle"></vue-feather>
            </button>
            <button @click="swiperNext()" class="carousel-control-next">
                <vue-feather type="arrow-right-circle"></vue-feather>
            </button>
        </div>
    </div>

    <swiper :modules="modules" :spaceBetween="10" :loop="true" :autoplay="true" ref="swiper_slider">
        <swiper-slide v-for="(item, i) in items" :key="i">
            <MediaCard :type="type" :class="'ratio_4x3'" :item="item" />
        </swiper-slide>
    </swiper>
</div>
</template>

<script>
import MediaCard from './cards/MediaCard'
import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import {
    Autoplay
} from "swiper";
import "swiper/css";
export default {
    components: {
        MediaCard,
        Swiper,
        SwiperSlide,
    },
    props: ['type', 'slider', 'title', 'items'],
    data() {
        return {
            swiper: null,
        };
    },
    setup() {
        return {
            modules: [Autoplay],
        };
    },
    mounted() {
        this.swiper = this.$refs.swiper_slider.$el.swiper
    },
    methods: {
        swiperPrev() {
            this.swiper.slidePrev();
        },
        swiperNext() {
            this.swiper.slideNext();
        },
    }
}
</script>

<style>

</style>
