<template>
	<div class="content_box p-4">
		<div
			class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
			<h2 class="title_block mb-0">Мои заказы</h2>
		</div>

		<DataTable
		 no_content_text="Еще нет созданных заказов">
			<template #toolbox>
				<div class="table_toolbox__item d-flex fw align-items-center mb-3">
					<span class="small text-muted me-2 d-none d-md-block">Показать</span>
					<select v-model="pageSize" class="form-select">
						<option :value="10">10</option>
						<option :value="20">20</option>
						<option :value="50">50</option>
						<option :value="100">100</option>
					</select>
				</div>
				<!-- <div class="table_toolbox__item table_toolbox__item__search mb-3">
					<input
						v-model="filter.name"
						type="text"
						class="form-control"
						placeholder="Поиск..." />
					<vue-feather v-if="filter.name" @click="clearFilterName" type="x" size="16" />
				</div> -->
			</template>
			<template #head>
				<tr>
					<th scope="col fw-bold">
						<SortTrigger
							@click="sort('id')"
							:active="currentSort === 'id' ? true : false"
							name="ID"
							:order="currentSortDir" />
					</th>
					<th scope="col fw-bold">
						Название
					</th>
					<th scope="col fw-bold">
						<SortTrigger
							@click="sort('status')"
							:active="currentSort === 'status' ? true : false"
							name="Статус"
							:order="currentSortDir" />
					</th>
					<th scope="col fw-bold">
						<SortTrigger
							@click="sort('created_at')"
							:active="currentSort === 'created_at' ? true : false"
							name="Дата"
							:order="currentSortDir" />
					</th>
					<th scope="col fw-bold"></th>
				</tr>
			</template>
			<template #default>
				<tr v-for="(item, i) in sortedPaginatedRows" :key="i">
					<td class="align-middle text-muted">#{{ item.id }}</td>
					<td class="align-middle">
						<router-link
							:to="'/shop/order/' + item.id"
							class="fw-bold text-decoration-none minw-200"
							>Заказ из интернет-магазина #{{ item.id }}</router-link
						>
					</td>
					<td class="align-middle">
						<ShopOrderStatusBadge :status="item.status" />
					</td>
					<td class="align-middle text-muted">{{ item.created_at }}</td>
					<td class="align-middle">
						<div class="table_actions d-flex align-items-center justify-content-end">
							<CDropdown direction="dropstart">
								<CDropdownToggle class="btn-circle text-primary">
									<vue-feather type="more-vertical"></vue-feather>
								</CDropdownToggle>
								<CDropdownMenu>
									<CDropdownItem v-if="item.status == 'pending_payment'" class="small">
										<a href="#" @click.prevent="toPayment(item.id)">К оплате</a>
									</CDropdownItem>
									<CDropdownItem class="small">
										<SwalButton
											class="text-danger"
											title="Вы действительно хотите удалить этот заказ?"
											text="После этого заявку уже нельзя будет восстановить и все действия, связанные с ней, прекратятся"
											icon="warning"
											confirmButtonText="Удалить"
											confirmButtonColor="#EA5455"
											@callback="deleteRequest"
											:callbackData="item.id">
											Удалить
										</SwalButton>
									</CDropdownItem>
								</CDropdownMenu>
							</CDropdown>
							<router-link :to="'/shop/order/' + item.id" class="btn-circle text-primary">
								<vue-feather type="eye"></vue-feather>
							</router-link>
						</div>
					</td>
				</tr>
			</template>
		</DataTable>

		<PaginationBox
			class="m-4 pb-4"
			:currentPage="currentPage"
			:pageSize="pageSize"
			:itemsLength="sortedRows.length"
			v-on:changeCurrentPage="changeCurrentPage" />
	</div>
</template>

<script>
import ShopOrderStatusBadge from '../UI/ShopOrderStatusBadge.vue'
import ExpiredStatus from '../UI/ExpiredStatus.vue'
import SortTrigger from '../table/SortTrigger.vue'
import DataTable from '../table/DataTable.vue'
import PaginationBox from '../PaginationBox'
import SwalButton from '../../components/SwalButton.vue'
import api from '@/store/api'
import moment from 'moment'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	components: {
		PaginationBox,
		CDropdown,
		CDropdownToggle,
		CDropdownMenu,
		CDropdownItem,
		SwalButton,
		DataTable,
		SortTrigger,
		ExpiredStatus,
		ShopOrderStatusBadge
	},
	data() {
		return {
			table_data: [],
			currentSort: 'created_at',
			currentSortDir: 'desc',
			pageSize: 10,
			currentPage: 1,
			is_visible: 0,
			filter: {
				name: '',
			},
			error_message: '',
		}
	},
	async mounted() {
		await this.fetchOrders()
		this.table_data = this.getOrders
	},
	methods: {
		...mapActions({
			fetchOrders: 'profile/fetchShopOrders',
			getPaymentUrl: 'shop/getPaymentUrl',
		}),
		async toPayment(order_id) {
			this.$swal({
                title: 'Перенаправление на форму оплаты',
                text: 'Пожалуйста, не закрывайте страницу',
                icon: 'info',
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })
			let res = await this.getPaymentUrl(order_id)
			if (res?.url) {
				window.location.href = res.url
			} else {
				this.$swal.close()
				this.$swal('Возникла неизвестная ошибка, попробуйте еще раз', '', 'error')
			}
		},
		sort: function (s) {
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
			}
			this.currentSort = s
		},
		changeCurrentPage(page) {
			this.currentPage = page
			return true
		},
		is_show(item) {
			if (
				this.filter.name == 0 ||
				item.event_name.toLowerCase().includes(this.filter.name.toLowerCase())
			) {
				item.is_visible = true
				return true
			} else {
				item.is_visible = false
				return false
			}
		},
		clearFilterName() {
			this.filter.name = ''
		},
		deleteRequest(id) {
			api
				.delete(process.env.VUE_APP_BASE_URL + 'shop/order/' + id + '/delete')
				.then((res) => {
					this.$swal('Заявка удалена', '', 'success')
					this.table_data = this.table_data.filter(function (e) {
						return e.id !== id
					})
				})
				.catch((error) => {
					console.log(error);
					this.error_message = error.response.data.message
					this.$swal(error.response.data.message, '', 'danger')
				})
		},
		orderExpired(id) {
			this.table_data = this.table_data.filter((item) => item.id != id)
		},
	},
	computed: {
		...mapGetters({
			getOrders: 'profile/getShopOrders',
		}),
		sortedRows: function () {
			return this.table_data
				.sort((a, b) => {
					let modifier = 1
					if (this.currentSortDir === 'desc') modifier = -1
					if (this.currentSort == 'created_at') {
						let a_time = moment(a[this.currentSort], 'DD-MM-YYYY HH:mm')
						let b_time = moment(b[this.currentSort], 'DD-MM-YYYY HH:mm')
						if (a_time < b_time) return -1 * modifier
						if (a_time > b_time) return 1 * modifier
					} else {
						if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
						if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier
					}
					return 0
				})
				.filter((row, index) => {
					if (this.is_show(row)) return true
				})
		},
		sortedPaginatedRows: function () {
			return this.sortedRows.filter((row, index) => {
				let start = (this.currentPage - 1) * this.pageSize
				let end = this.currentPage * this.pageSize
				if (index >= start && index < end) return true
			})
		},
	},
}
</script>

<style scoped>
.dropdown-menu {
	min-width: 150px;
}

.dropdown-toggle:after,
.dropdown-toggle:before {
	display: none;
}

.dropdown-menu a {
	text-decoration: none;
	color: #071031;
}
</style>