import axios from 'axios'
import Swal from 'sweetalert2'
import store from '../store'
import api from '@/store/api'

export function storeAthleteAPI(id = null, data) {
	let url = id ? `athlete/${id}/edit` : 'athlete/create'

	axios
		.post(process.env.VUE_APP_BASE_URL + url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
				authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
		.then((res) => {
			if (res.data.success) {
				store.commit('athlets/changeAthlete', res.data.athlete_data)
				store.commit('profile/changeAthlete', res.data.athlete_data)
				Swal.fire('Изменения сохранены', '', 'success')
			} 
            else if (res.data.error) {
                Swal.fire(res.data.message, '', 'danger')
            }
            else {
				console.log(res, res.data)
				Swal.fire(
					'Возникла ошибка при сохранении',
					'пожалуйста, попробуйте еще раз',
					'danger'
				)
			}
		})
		.catch((error) => {
			console.log(error)
			Swal.fire(error.response.data.message, '', 'danger')
		})
}

export function deleteAthleteAPI(id) {
	api
		.delete(process.env.VUE_APP_BASE_URL + 'athlete/' + id + '/delete')
		.then((res) => {
            store.commit('profile/removeAthlete', id)
		})
		.catch((error) => {
			console.log(error)
			Swal.fire(error.response.data.message, '', 'danger')
		})
}

export async function checkAgeLimit(data) {
    let response = false;
	await api
		.post(`${process.env.VUE_APP_BASE_URL}agelimit/hasplaces`, data)
		.then((res) => {
			if (res.data.success) {
				response = res.data.has_places
			}
		})
		.catch((error) => {})
    return response
}
