<template>
	<div class="big_image_carousel" v-if="allSlides.length">
		<swiper
			@slideChange="swiperSlideChange"
			:modules="modules"
			:spaceBetween="10"
			:loop="true"
            :autoplay="{
                delay: 3500,
                disableOnInteraction: true,
            }"
			ref="swiper_slider">
			<div class="carousel-indicators d-none d-lg-flex">
				<div
					class="pagination_bullet"
					v-for="(item, i) in allSlides"
					:key="i"
					:class="{ active: item.isActive }"
					@click="swiperTo(i)">
					{{ shortTitile(item.title) }}
				</div>
			</div>
			<swiper-slide
				v-for="(item, i) in allSlides"
				:key="i"
				:style="{ 'background-image': 'url(' + slideImage(item) + ')' }">
				<div class="carousel-item__wrapper">
					<div
						@click="slideLink(item.link)"
						class="carousel-item__overflow"
						:class="item.link ? 'cursor-pointer' : ''"></div>
					<div class="container h-100">
						<div class="carousel-nav mb-3 animated fadeInDown">
							<button
								class="carousel-control-prev me-3"
								type="button"
								@click="swiperPrev()">
								<vue-feather type="arrow-left-circle"></vue-feather>
							</button>
							<button class="carousel-control-next" type="button" @click="swiperNext()">
								<vue-feather type="arrow-right-circle"></vue-feather>
							</button>
						</div>
						<p
							@click="slideLink(item.link)"
							class="carousel-item__title intro_title_medium text-white animated fadeInUp"
							:class="item.link ? 'cursor-pointer' : ''">
							{{ item.title }}
						</p>
					</div>
				</div>
			</swiper-slide>
		</swiper>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper'
import 'swiper/css'
import { mapGetters } from 'vuex'

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			swiper: null,
			pagination: false,
			paginationBullets: [],
		}
	},
	setup() {
		return {
			modules: [Autoplay],
		}
	},
	async mounted() {
		this.swiper = this.$refs.swiper_slider.$el.swiper
	},
	methods: {
		slideImage(item) {
			if (window.innerWidth < 768) {
				return item.img_mobile
			} else {
				return item.img
			}
		},
		shortTitile(title) {
			if (title.length > 45) {
				return String(title).substr(0, 45) + '...'
			} else {
				return title
			}
		},
		swiperPrev() {
			this.swiper.slidePrev()
		},
		swiperNext() {
			this.swiper.slideNext()
		},
		swiperTo(i) {
			this.swiper.slideToLoop(i)
		},
		swiperSlideChange(e, i) {
			for (i = 0; i < this.allSlides.length; i++) {
				this.allSlides[i].isActive = false
			}
			this.allSlides[this.$refs.swiper_slider.$el.swiper.realIndex].isActive = true
		},
		slideLink(link) {
			if (!link) return false
			if (link.includes('http')) {
				location.href = link
			} else {
				this.$router.push(link)
			}
		},
	},
	computed: {
		...mapGetters({
			allSlides: 'home/allSlides',
		}),
	},
}
</script>

<style>
.big_image_carousel .carousel-item__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 650px;
}

.big_image_carousel .swiper-slide {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
}

.big_image_carousel .carousel-item__title {
	max-width: 835px;
	text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
	margin-bottom: 60px;
	text-decoration: none;
}

.big_image_carousel .carousel-item__overflow {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to right, rgba(0, 31, 141, 0.8) 30%, transparent);
}

.big_image_carousel .container {
	position: relative;
}

.big_image_carousel .carousel-nav button {
	opacity: 0.3;
	transition: opacity 0.4s;
	color: #fff;
}

.big_image_carousel .carousel-nav button:hover,
.big_image_carousel .carousel-nav button:focus {
	opacity: 0.5;
	color: #fff;
}

.big_image_carousel .carousel-indicators {
	margin: 0;
	background: linear-gradient(to top, #030c2f, transparent);
	padding: 1rem 0;
}

.big_image_carousel .carousel-indicators .pagination_bullet {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: auto;
	height: auto;
	padding: 1rem;
	margin-right: 0;
	margin-left: 0;
	text-indent: inherit;
	cursor: pointer;
	background-color: transparent;
	background-clip: padding-box;
	border: 0;
	border-top: none;
	border-bottom: none;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	opacity: 0.2;
	transition: opacity 0.6s ease;
	color: #fff;
	max-width: 200px;
	cursor: pointer;
}

.big_image_carousel .carousel-indicators .pagination_bullet:last-child {
	border-right: none;
}

.big_image_carousel .carousel-indicators .pagination_bullet:hover,
.big_image_carousel .carousel-indicators .pagination_bullet.active {
	opacity: 1;
}

.carousel-nav {
	position: relative;
	display: flex;
	align-items: center;
}

.carousel-nav button {
	position: relative;
	width: auto;
	transition: all 0.4s;
	color: #071031;
}

.carousel-nav button:hover,
.carousel-nav button:focus {
	color: #005eff;
}

.carousel-nav button svg {
	width: 40px;
	height: 40px;
}

.cursor-pointer {
	cursor: pointer;
}
</style>
