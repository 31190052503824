<template>
  <div class="page-loader">
		<div class="d-flex justify-content-center align-items-center h-100 py-5 text-primary fw-bold">
			<vue-feather type="loader" animation="spin" animation-speed="slow"></vue-feather>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .page-loader{
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        background: rgba(255, 255, 255, 0.47);
        z-index: 9999;
        backdrop-filter: blur(10px);
    }
</style>