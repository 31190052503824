<template>
<CModal size="lg" alignment="center" :visible="getOffcanvas(id)" @close="closeOffcanvas(id)">
    <button @click="closeOffcanvas(id)" type="button" class="btn-close text-reset"></button>
    <div class="modal-body">
        <slot />
    </div>
</CModal>
<div></div>
</template>

<script>
import {
    mapGetters,
    mapMutations
} from "vuex";
import {
    CModal
} from '@coreui/vue'
export default {
    props: ['id'],
    components: {
        CModal, 
    },
    computed: {
        ...mapGetters({
            getOffcanvas: "global/getOffcanvas",
        }),
    },
    methods: {
        ...mapMutations({
            toggleOffcanvas: "global/toggleOffcanvas",
            closeOffcanvas: "global/closeOffcanvas",
        }),
    }
}
</script>

<style scoped>
.modal-content{
    border: none;
}

.btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.5;
    z-index: 1000;
}

.modal-body{
    padding: 60px 45px;
}

@media(max-width: 768px){

    .btn-close{
        top: 10px;
        right: 10px;
    }

    .modal-body{
        padding: 30px 15px;
    }
}

</style>
