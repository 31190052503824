<template>
<!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
<!-- <router-view /> -->
<!-- <metainfo>
    <template v-slot:title="{ content }">{{ content ? content : 'Swim4you.ru' }}</template>
</metainfo> -->
<Header v-if="this.$route.name !== 'login' && this.$route.name !== 'registration' && this.$route.name !== 'forgot-password' && this.$route.name !== 'email_verify' && this.$route.name !== 'reset_password'"  />
<router-view v-slot="{ Component, route }">
    <transition name="fade" mode="out-in">
        <div :key="route.name">  
            <component :is="Component"></component>
        </div>
    </transition>
</router-view>
</template>

<script>
import Header from '@/components/header/Header.vue'
import setMeta from "@/views/seoMeta"
export default {
    components: {
        Header
    },
    mounted() {
        setMeta(
            'Технология Спорта | Swim4you.ru',
            'Соревнования, тренировочные сборы, мастер-классы, методическое обеспечение подготовки',
            'https://swim4you.ru/assets/img/logo.png',
            false
        )
    },
}
</script>

<style>
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .05s ease;
}
</style>
