<template>
<default-layout>
    <template #content>
        <PageTitle title="Рейтинги" :multiple_titles="[
                {
                    title: 'Рейтинги',
                    route_name: 'rating'
                },
                {
                    title: 'Рекорды',
                    route_name: 'records'
                },
				{
                    title: 'Турнирные таблицы',
                    route_name: 'tournaments'
                },
				{
                    title: 'Архив результатов',
                    route_name: 'results'
                },
            ]" />
        <div class="page_section">
            <div class="container">
                <RatingTable class="mb-5" />
                <RelaysTable />
            </div>
        </div>
    </template>
</default-layout>
</template>

<script>
import RelaysTable from '../components/RelaysTable.vue'
import PageTitle from '../components/PageTitle.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import RatingTable from '../components/RatingTable.vue'
import setMeta from "@/views/seoMeta"
export default {
    components: {
        RatingTable,
        DefaultLayout,
        PageTitle,
        RelaysTable
    },

    mounted() {
        setMeta(
            'Рейтинги | Swim4you.ru', 
            null, 
            null,
            false
        )
    },
}
</script>

<style>

</style>
