<template>
<default-layout>
    <template v-if="loaded" #content>
        <div class="page_section">
            <BigSlider />
        </div>

        <div class="page_section">
            <div class="container">
                <div class="big_title">
                    <h2 class="section_title">Календарь мероприятий</h2>
                </div>
            </div>

            <HomeEventTabs />
        </div>

        <div class="page_section" style="overflow: hidden;">
            <HomeTimelineCarousel />
        </div>

        <div class="page_section">
            <div class="container">
                <div class="big_title d-block d-md-flex justify-content-between align-items-center">
                    <h2 class="section_title mb-3 mb-md-0">Новости</h2>
                    <router-link to="/news" class="btn btn-rounded btn-outline-secondary py-3 px-5 fw-bold">Все новости</router-link>
                </div>
            </div>
            <HomeNewsGrid />
        </div>

        <div class="page_section">
            <div class="container">
                <div class="row g-4">
                    <div class="col-md-6" v-if="getMediaPhoto().length > 0">
                        <MediaBoxSlider :title="'Фото'" :type="'video'" :items="getMediaPhoto()" />
                    </div>
                    <div class="col-md-6" v-if="getMediaVideo().length > 0">
                        <MediaBoxSlider :title="'Видео'" :type="'photo'" :items="getMediaVideo()" />
                    </div>
                </div>
            </div>
        </div>

        <div class="page_section">
            <HomePartners />
        </div>
    </template>
</default-layout>
</template>

<script>
import HomeNewsGrid from '../components/home/HomeNewsGrid.vue'
import HomePartners from '../components/home/HomePartners'
import HomeTimelineCarousel from '../components/home/HomeTimelineCarousel'
import HomeEventTabs from '../components/home/HomeEventTabs'
import EventCard from '../components/cards/EventCard.vue'
import BigSlider from "../components/home/BigSlider.vue";
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import MediaBoxSlider from '@/components/MediaBoxSlider.vue';
import {
    mapGetters, mapActions
} from "vuex";
export default {
    data(){
        return {
            loaded: false
        }
    },
    async mounted() {
        await this.fetchHome()
        this.loaded = true
    },
    components: {
        HomePartners,
        HomeTimelineCarousel,
        HomeEventTabs,
        BigSlider,
        DefaultLayout,
        EventCard,
        MediaBoxSlider, HomeNewsGrid
    },
    methods: {
        ...mapGetters({
            getMediaPhoto: "home/mediaPhoto",
            getMediaVideo: "home/mediaVideo",
        }),
        ...mapActions({
            fetchHome: "home/fetchHome",
        })
    }
};
</script>

<style>
</style>
