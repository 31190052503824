<template>
    <div v-if="NEWS.length > 0" class="home_news_grid">
        <div class="container">
            <div class="row">
                <div class="col-md-6 py-2 py-md-0">
                    <NewsCard :post="sortedNews[0]" />
                </div>
                <div class="col-md-6 d-flex flex-column justify-content-between py-2 py-md-0">
                    <div class="row">
                        <div v-if="sortedNews[1]" class="col-12 py-2 py-md-0 pb-md-4">
                            <NewsCard :post="sortedNews[1]" :class="'card_sm_height'" />
                        </div>
                        <div v-if="sortedNews[2]" class="col-12 py-2 py-md-0 pb-md-4">
                            <NewsCard :post="sortedNews[2]" :class="'card_sm_height'" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewsCard from '../cards/NewsCard.vue'
import moment from 'moment'
import {
    mapGetters,
} from "vuex";
export default {
    components: { NewsCard },
    computed: {
        ...mapGetters({
            NEWS: "home/news",
        }),
        sortedNews(){
            let ordered_posts = this.NEWS.filter(item => item.order > 0).sort((a, b) => {
                if(a.order === b.order) return moment(b.date, 'DD-MM-YYYY') - moment(a.date, 'DD-MM-YYYY')
                return a.order - b.order
            })

            let date_posts = this.NEWS.filter(item =>
                !ordered_posts.includes(item)
            ).sort((a, b) => {
                return moment(b.date, 'DD-MM-YYYY') - moment(a.date, 'DD-MM-YYYY') 
            })

            return ordered_posts.concat(date_posts)
        },
    }
};
</script>

<style>
</style>
