<template>
    <li
        class="nav-item dropdown"
        :class="el.link === this.$route.path ? 'current' : ''"
    >
        <router-link
            v-if="el.link"
            class="nav-link fw-bold"
            :to="el.link"
            data-bs-toggle="dropdown"
        >
            {{ el.name }}
            <vue-feather
                v-if="el.children.length"
                type="chevron-down"
                size="12"
            ></vue-feather>
        </router-link>
        <a
            v-if="el.external_link"
            class="simple_link nav-link fw-bold"
            :href="el.external_link"
            data-bs-toggle="dropdown"
        >
            {{ el.name }}
            <vue-feather
                v-if="el.children.length"
                type="chevron-down"
                size="12"
            ></vue-feather>
        </a>
        <ul
            v-if="el.children.length"
            class="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
        >
            <li v-for="child in el.children" :key="child.id">
                <router-link v-if="child.link" class="dropdown-item" :to="child.link">
                    {{ child.name }}
                </router-link>
                <a
                    v-if="child.external_link"
                    class="dropdown-item"
                    :href="child.external_link"
                >
                    {{ child.name }}
                </a>
            </li>
        </ul>
    </li>
</template>

<script>
export default {
    props: {
        el: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style>
</style>