import { useHead } from '@vueuse/head'

function setMeta( title = null, description = null, image = null, robots = false){
    let url = window.location.href
    let meta_obj = {
        htmlAttrs: { lang: 'ru', amp: true },
        link: [
            { 
                rel: 'canonical', 
                href: url 
            },
        ],
    }
    let meta = [
        {
            name: 'robots',
            content: robots ? 'noindex, nofollow' : 'index, follow',
        },
        {
            name: 'og:url', 
            content: url,
        },
        
        {
            name: 'og:locale', 
            content: 'ru_RU',
        },
        {
            name: 'og:type', 
            content: 'website',
        }
    ];
    if(title){
        meta_obj.title = title
        meta.push({
            name: 'og:site_name', 
            content: title,
        },
        {
            name: 'og:title', 
            content: title,
        })
    }
    if(description){
        meta_obj.description = description
        meta.push({
            name: 'description', 
            content: description,
        },)
        meta.push({
            name: 'og:description', 
            content: description,
        },)
    }
    if(image){
        meta.push({
            name: 'thumbnail', 
            content: image,
        },
        {
            name: 'og:image', 
            content: image,
        },
        {
            name: 'og:image:secure_url', 
            content: image,
        })
    }

    meta_obj.meta = meta

    useHead(meta_obj)
}

export default setMeta