<template>
<div v-if="isAllowed" >
<div class="extended_check" :class="theModel.quantity > 0 ? 'active' : ''">
    <div class="row gy-y justify-content-between align-items-center">
        <div class="col">
            <div class="form-check mb-3 mb-md-0">
                <input @change.prevent="check" class="form-check-input ml-2" type="checkbox" :checked="theModel.quantity > 0">

                <div class="form-check-label ps-3">
                    <a @click.prevent="modal_visible = true" v-if="item.name" href="#" class="d-block fw-bold text_20 mb-2" :class="theModel.quantity > 0 ? 'text-success' : ''">{{item.name}}</a>

                    <p v-if="item.short_description" class="mb-2">{{item.short_description}}</p>

                    <p v-if="theModel.quantity > 0" class="text-success fw-bold mb-0">{{theModel.quantity}} x {{item.price}} ₽</p>
                </div>

            </div>
        </div>
        <div class="ps-5 ps-md-0 col-md-3">
            <p class="mb-1 text-md-center"><span class="fw-bold">{{item.price}} ₽</span> <span class="text-muted">/ {{item.measure ? item.measure : 'услуга'}}</span></p>

            <a v-if="theModel.quantity < 1" @click.prevent="modal_visible = true" href="#" class="btn btn-primary btn-primary-shadow btn-rounded">Добавить</a>

            <a v-if="theModel.quantity > 0" @click.prevent="modal_visible = true" href="#" class="btn btn-warning btn-rounded">Изменить</a>
        </div>
    </div>
</div>
<UpsellForDistances 
    v-if="item.type == 'for_distances'"
    v-model="theModel"
    :item="item"
    :distances="distances"
    :visible="modal_visible"
    @hide="modal_visible = false"
/>
<UpsellForAthletes 
    v-if="item.type == 'for_athletes'"
    v-model="theModel" 
    :item="item"
    :distances="distances"
    :visible="modal_visible"
    @hide="modal_visible = false"
/>
<UpsellDefault 
    v-if="item.type == 'default'"
    v-model="theModel" 
    :item="item"
    :visible="modal_visible"
    @hide="modal_visible = false"
/>
</div>
</template>

<script>
import UpsellForAthletes from '../offcanvases/UpsellForAthletes.vue'
import UpsellDefault from '../offcanvases/UpsellDefault.vue'
import UpsellForDistances from '../offcanvases/UpsellForDistances.vue'
import { computed } from 'vue';
export default {
    props: {
        item: {
            type: Object,
            default: {},
        },
        distances: {
            type: Array,
            default: [],
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
    },
    data(){
        return {
            modal_visible: false,
        }
    },
    computed: {
        isAllowed(){
            if(this.item.type != 'for_distances') return true
            if(!this.item.for_distances || !this.item.for_distances.length) return true
            let distances = []
            this.distances.forEach(item => {
                item.distances.forEach(el => {
                    distances.push(el.distance)
                })
            })
            return distances.some(r => this.item.for_distances.indexOf(r.toString()) >= 0)
        }
    },
    methods: {
        check(e, type) {
            if(e.target.checked === true){
                //this.theModel.quantity = 1
                this.modal_visible = true
            } else {
                this.theModel.quantity = 0
            }
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const theModel = computed({  
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });
        return { theModel };
    },
    components: { UpsellForDistances, UpsellDefault,
        UpsellForAthletes },
}
</script>

<style>

</style>
