<template>
	<div ref="offer_block" v-if="OFFER_BLOCK && OFFER_BLOCK?.enable_offer != '0' && OFFER_BLOCK?.background_img && OFFER_BLOCK?.title" class="container">
		<div class="subcribe_section">
			<p class="title">{{OFFER_BLOCK.title}}</p>
			<p v-if="OFFER_BLOCK?.subtitle" class="subtitle lead">
				{{OFFER_BLOCK.subtitle}}
			</p>
            <template v-if="OFFER_BLOCK?.btn_text && OFFER_BLOCK?.btn_link">
                <router-link v-if="buttonType == 'route'" :to="OFFER_BLOCK.btn_link" class="btn btn-warning px-5 mt-4">
                    {{OFFER_BLOCK.btn_text}}
                </router-link>
                <a v-else :href="OFFER_BLOCK.btn_link" class="btn btn-warning px-5 mt-4" target="_blank">{{OFFER_BLOCK.btn_text}}</a>
            </template>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    updated(){
        if(this.blockHeight){
            this.$store.commit('global/setFooterTopPadding', this.blockHeight / 2)
        }
    },
	computed: {
        ...mapGetters({
			OFFER_BLOCK: 'global/getOfferBlock',
		}),
        buttonType(){
            if(!this.OFFER_BLOCK) return
            if(!this.OFFER_BLOCK?.btn_link || this.OFFER_BLOCK.btn_link.includes('http')) return 'link';
            return 'route';
        },
        blockHeight(){
            if(!this.$refs.offer_block) return 0
            return this.$refs.offer_block.clientHeight;
        }
	},
}
</script>

<style></style>
