<template>
<div class="container">
    <div class="big_title d-block d-md-flex justify-content-between align-items-center">
        <h2 class="section_title mb-3 mb-md-0">Партнеры</h2>
        <div class="carousel-nav mb-3">
            <button @click="swiperPrev()" class="carousel-control-prev me-3" >
                <vue-feather type="arrow-left-circle"></vue-feather>
            </button>
            <button @click="swiperNext()" class="carousel-control-next" >
                <vue-feather type="arrow-right-circle"></vue-feather>
            </button>
        </div>
    </div>
</div>
<div class="partners_carousel">
    <div class="container">
        <swiper :modules="modules" :spaceBetween="30" :loop="true" :slidesPerView="4" :breakpoints="this.swiper_breakpoints" :autoplay="true" ref="swiper_slider">
            <swiper-slide v-for="(item, i) in getPartners()" :key="i">
                <a :href="item.link" class="partners__item" target="_blank" rel="nofollow">
                    <img :src="item.banner" />
                </a>
            </swiper-slide>
        </swiper>
    </div>
</div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import {
    Autoplay
} from "swiper";
import "swiper/css";
import {
    mapGetters,
} from "vuex";
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiper: null,
            swiper_breakpoints: {
                '0': {
                    slidesPerView: 2,
                },
                '1024': {
                    slidesPerView: 3,
                },
                '1200': {
                    slidesPerView: 4,
                },
            }
        };
    },
    setup() {
        return {
            modules: [Autoplay],
        };
    },
    mounted() {
        this.swiper = this.$refs.swiper_slider.$el.swiper
    },
    methods: {
        swiperPrev() {
            this.swiper.slidePrev();
        },
        swiperNext() {
            this.swiper.slideNext();
        },
        ...mapGetters({
            getPartners: "home/partners",
        })
    }
}
</script>

<style>

</style>
