<template>
	<OffcanvasInline
		id="add_club"
		@show="showModal"
		@hide="$emit('hide'), (visible = !visible)"
		:visible="visible">
		<p v-if="selected_club" class="section_title_medium mb-2">
			Редактировать клуб
		</p>
		<p v-else class="section_title_medium mb-2">Добавить клуб</p>
		<p class="text-muted">Пожалуйста, внимательно заполните все поля</p>
		<form @submit.prevent="storeClub" v-if="visible">
			<label v-if="!selected_club && select_in_list" class="mb-3 d-block w-100">
				<span class="fw-bold">
					Выберите клуб
					<span class="text-danger">*</span></span
				>
				<VSelect
					v-model="list_selected_club"
					label="name"
					:options="CLUBS"
					:reduce="(option) => option.name"
					:dropdown-should-open="showOptionsList"
					@option:selected="selectInList"
					placeholder="Начните вводить название"
					:required="selected_club && !list_selected_club"
					:class="show_errors && !list_selected_club ? 'invalid border-danger' : ''">
					<template #no-options>
						<div>Нет подходящих вариантов</div>
					</template>
				</VSelect>
			</label>

			<a href="#" @click.prevent="select_in_list = !select_in_list" class=" d-table mb-4 text-muted">{{select_in_list ? 'Моего клуба нет в списке' : 'Поиск по списку'}}</a>

			<label v-if="!select_in_list" class="mb-3 d-block w-100">
				<span class="fw-bold">
					Название клуба
					<span class="text-danger">*</span></span
				>
				<input
					v-model="form_data.name"
					type="text"
					class="form-control"
					placeholder="Введите текст"
					required />
			</label>
			<label v-if="!select_in_list" class="mb-3 d-block w-100">
				<span class="fw-bold">
					Короткое название
					<span class="text-danger">*</span></span
				>
				<input
					v-model="form_data.short_name"
					type="text"
					class="form-control"
					placeholder="Введите текст"
					maxlength="16"
					required />
				<span class="text-muted small"
					>Для размещения на табло. Не более 16 символов</span
				>
			</label>
			<label v-if="!select_in_list" class="d-block mb-3">
				<span class="fw-bold">Страна <span class="text-danger">*</span></span>
				<select v-model="form_data.country" class="form-select" required>
					<option :value="null" disabled>Выберите страну</option>
					<option v-for="(value, key) in COUNTRIES" :key="key" :value="key">
						{{ value }}
					</option>
				</select>
			</label>
			<label v-if="form_data.country == 'RUS' && !select_in_list" class="d-block mb-3">
				<span class="fw-bold">Субъект <span class="text-danger">*</span></span>
				<select v-model="form_data.state" class="form-select" required>
					<option :value="null" disabled>Выберите субъект</option>
					<option v-for="item in RUSSIA_SUBJECTS" :key="item.name" :value="item.code">
						{{ item.name }}
					</option>
				</select>
			</label>
			<label v-if="form_data.country == 'RUS' && form_data.state && !select_in_list" class="d-block mb-3">
				<span class="fw-bold">Город <span class="text-danger">*</span></span>
				<select v-model="form_data.city" class="form-select" required>
					<option :value="null" disabled selected>Выберите город</option>
					<option v-for="item in getRussiaCitiesBySubject(form_data.state)" :key="item.name" :value="item.name">
						{{ item.name }}
					</option>
				</select>
			</label>
			<label v-if="form_data.country && form_data.country != 'RUS' && !select_in_list" class="d-block mb-3">
				<span class="fw-bold">Город <span class="text-danger">*</span></span>
				<input
					v-model="form_data.city"
					type="text"
					class="form-control"
					placeholder="Название города"
					required />
			</label>
			<div class="form-check bg-body mb-3 p-2 its_me_check">
				<label class="form-check-label small fw-bold" for="auth_accept">
					Представитель – это я
				</label>
				<input
					v-model="its_me"
					@change="itsMeMario"
					class="form-check-input"
					type="checkbox"
					value="1"
					id="auth_accept" />
			</div>
			<label class="mb-3 d-block w-100">
				<span class="fw-bold"
					>ФИО представителя <span class="text-danger">*</span></span
				>
				<input
					v-model="form_data.agent_name"
					type="text"
					class="form-control"
					placeholder="Введите текст"
					required />
			</label>
			<label class="mb-3 d-block w-100">
				<span class="fw-bold">Телефон представителя <span class="text-danger">*</span></span>
				<input
					v-model="form_data.agent_phone"
					type="tel"
					class="form-control"
					placeholder="+7 (___) ___ ____"
					v-maska="'+7 (###) ### ####'"
					required />
			</label>
			<label class="mb-3 d-block w-100">
				<span class="fw-bold">Email представителя <span class="text-danger">*</span></span>
				<input
					v-model="form_data.agent_email"
					type="email"
					class="form-control"
					placeholder="Введите email"
					required />
			</label>

			<button
				type="submit"
				class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">
				Сохранить
			</button>
		</form>
	</OffcanvasInline>
</template>

<script>
import OffcanvasInline from "./OffcanvasInline.vue"
import api from "@/store/api"
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { mapGetters } from "vuex"
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		selected_club: {
			type: Object,
		},
	},
	data() {
		return {
			form_data: {
				name: null,
				short_name: null,
				city: null,
				country: null,
				state: null,
				agent_name: null,
				agent_phone: null,
				agent_email: null,
			},
			its_me: false,
			select_in_list: true,
			list_selected_club: null,
			show_errors: false
		}
	},
	computed: {
		...mapGetters({
			COUNTRIES: "global/getCountries",
			RUSSIA_SUBJECTS: "global/RUSSIA_SUBJECTS",
			RUSSIA_CITIES: "global/RUSSIA_CITIES",
			CLUBS: "global/CLUBS",
			USERDATA: "profile/getUserData",
			getRussiaCitiesBySubject: "global/getRussiaCitiesBySubject",
		}),
	},
	mounted(){
		if(this.selected_club){
			this.select_in_list = false
		}
	},
	methods: {
		selectInList(value){
			this.form_data.name = value.name
			this.form_data.short_name = value.short_name
			this.form_data.city = value.city
			this.form_data.country = value.country
			this.form_data.state = value.state
		},
		showOptionsList(VueSelect) {
			if (this.selected_club !== null) {
				return VueSelect.open
			}
			return VueSelect.search.length !== 0 && VueSelect.open
		},
		itsMeMario(e) {
			if (this.its_me) {
				if (this.USERDATA.name) this.form_data.agent_name = this.USERDATA.name
				if (this.USERDATA.phone)
					this.form_data.agent_phone = this.USERDATA.phone
				if (this.USERDATA.email)
					this.form_data.agent_email = this.USERDATA.email
			} else {
				this.form_data.agent_name = null
				this.form_data.agent_phone = null
				this.form_data.agent_email = null
			}
		},
		showModal() {
			if (this.selected_club) {
				this.form_data = Object.assign(this.form_data, this.selected_club)
			}
		},
		hasErrors(){
			let isNullish = false;
			Object.values(this.form_data).every(value => {
				if (value === null) {
					isNullish = true;
				}
			});

			return isNullish;
		},
		async storeClub() {
			if(this.form_data.country != 'RUS'){
				this.form_data.state = this.form_data.country
			}
			if(this.hasErrors()){
				this.show_errors = true
				return
			}
			if (this.form_data.id) {
				await api
					.post(
						process.env.VUE_APP_BASE_URL +
							"club/" +
							this.form_data.id +
							"/edit",
						this.form_data
					)
					.then((res) => {
						console.log(res)
						this.$swal("Изменения сохранены", "", "success")
						this.$emit("changeData")
					})
					.catch((error) => {
						console.log(error)
						this.error_message = error.response.data.message
						this.$swal(error.response.data.message, "", "danger")
					})
			} else {
				await api
					.post(process.env.VUE_APP_BASE_URL + "club/create", this.form_data)
					.then((res) => {
						this.$swal("Изменения сохранены", "", "success")
						this.$emit("changeData")
					})
					.catch((error) => {
						console.log(error)
						this.error_message = error.response.data.message
						this.$swal(error.response.data.message, "", "danger")
					})
			}
		},
	},
	components: {
		OffcanvasInline,
		VSelect,
	},
	emits: ["changeData"],
}
</script>

<style>
.its_me_check {
	padding-left: 40px !important;
	border-radius: 5px;
}

.invalid .vs__dropdown-toggle{
	border-color: red !important;
}
</style>