<template>
    <div class="content_box p-4 mb-4">
        <div
            class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
            <h2 class="title_block">Создайте команду</h2>
        </div>
        <div class="row g-3">
            <div class="col-lg-6 col-xl-4">
                <div class="input_add_edit row gx-1">
                    <div class="col-auto">
                        <label for="add_edit">
                            <span class="fw-bold">
                                Выберите клуб
                                <span class="text-danger">*</span></span>
                        </label>
                    </div>
                    <div class="col text-end">
                        <a
                            @click.prevent="modals_visible.add_club = true"
                            href="#"
                            class="text-muted small"
                            >Добавить новый</a>
                    </div>
                    <div class="col-12">
                        <select
                            v-model="club_name"
                            @change="selectClub"
                            id="add_edit"
                            class="form-select"
                            :class="show_errors && !club_name ? 'invalid' : ''"
                            required>
                            <option :value="null" disabled>
                                Выберите вариант
                            </option>
                            <option
                                v-for="(item, i) in CLUBS"
                                :key="i"
                                :data-club="item"
                                :value="item.name">
                                {{ item.name }}
                            </option>
                        </select>
                        <p v-if="show_errors && !club_name" class="mb-0 small text-danger">Обязательное поле</p>
                    </div>
                    <div v-if="selected_club" class="col-12">
                        <a
                            @click.prevent="modals_visible.edit_club = true"
                            href="#"
                            class="text-muted small"
                            >Редактировать выбранный</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <label class="d-block">
                    <span class="fw-bold"
                        >Короткое название
                        <span class="text-danger">*</span></span
                    >
                    <input
                        v-model="club_shortname"
                        type="text"
                        class="form-control"
                        :class="show_errors && !club_shortname ? 'invalid' : ''"
                        placeholder="До 16 символов"
                        required
                    />
                    <p v-if="show_errors && !club_shortname" class="mb-0 small text-danger">Обязательное поле</p>
                </label>
            </div>
            <div class="col-lg-6 col-xl-2">
                <label class="d-block">
                    <span class="fw-bold">Код субъекта</span>
                    <input
                        v-model="club_state"
                        type="text"
                        class="form-control"
                        :class="show_errors && !club_state ? 'invalid' : ''"
                        disabled
                    />
                    <p v-if="show_errors && !club_state" class="mb-0 small text-danger">Обязательное поле</p>
                </label>
            </div>
            <div class="col-lg-6 col-xl-3">
                <label class="d-block">
                    <span class="fw-bold">Город</span>
                    <input
                        v-model="club_city"
                        type="text"
                        class="form-control"
                        :class="show_errors && !club_state ? 'invalid' : ''"
                        disabled
                    />
                    <p v-if="show_errors && !club_state" class="mb-0 small text-danger">Обязательное поле</p>
                </label>
            </div>
        </div>
    </div>
    <AddClub
        @changeData="changeClubs"
        :visible="modals_visible.add_club"
        @hide="modals_visible.add_club = false"
    />
    <AddClub
        :selected_club="this.selected_club"
        @changeData="changeClubs"
        :visible="modals_visible.edit_club"
        @hide="modals_visible.edit_club = false"
    />
</template>

<script>
import AddClub from '../../components/offcanvases/AddClub.vue'
import {
    mapGetters, mapActions
} from "vuex"
import { computed } from 'vue';
export default {
    props: {
        club_name: {
            type: String,
            default: () => ({}),
        },
        club_shortname: {
            type: String,
            default: () => ({}),
        },
        club_state: {
            type: String,
            default: () => ({}),
        },
        club_city: {
            type: String,
            default: () => ({}),
        },
        has_errors: {
            type: Boolean,
            default: () => (false),
        },
        show_errors: {
            type: Boolean,
            default: () => (false),
        },
    },
    components: { AddClub },
    emits: ['update:club_name','update:club_shortname','update:club_state','update:club_city', 'update:has_errors', 'selectClub'],
    setup(props, { emit }) {
        const club_name = computed({  
            get: () => props.club_name,
            set: (value) => emit('update:club_name', value),
        });
        const club_shortname = computed({  
            get: () => props.club_shortname,
            set: (value) => emit('update:club_shortname', value),
        });
        const club_state = computed({  
            get: () => props.club_state,
            set: (value) => emit('update:club_state', value),
        });
        const club_city = computed({  
            get: () => props.club_city,
            set: (value) => emit('update:club_city', value),
        });
        const has_errors = computed({  
            get: () => props.has_errors,
            set: (value) => emit('update:has_errors', value),
        });
        return { club_name, club_shortname, club_state, club_city, has_errors};
    },
    data(){
        return {
            modals_visible: {
                add_club: false,
                edit_club: false,
            },
            selected_club: null
        }
    },
    async mounted(){
        await this.fetchClubs()
    },
    beforeUpdate(){
        this.validateFields()
    },
    computed: {
        ...mapGetters({
            CLUBS: "profile/getClubs",
        }),
    },
    methods: {
        ...mapActions({
            fetchClubs: "profile/fetchClubs",
        }),
        selectClub(e){
            this.club_shortname = null
            this.club_state = null
            this.club_city = null

            this.selected_club = this.CLUBS.find(item => {
                return item.name === e.target.value
            })

            if(this.selected_club.short_name) this.club_shortname = this.selected_club.short_name
            if(this.selected_club.state) this.club_state = this.selected_club.state
            if(this.selected_club.city) this.club_city = this.selected_club.city

            this.$emit('selectClub', this.selected_club)
        },
        async changeClubs(){
            await this.fetchClubs()
        },
        validateFields(){
            if(!this.club_name || !this.club_shortname || !this.club_state || !this.club_city){
                this.has_errors = true
            } else {
                this.has_errors = false
            }
        }
    }
};
</script>

<style>
</style>