<template>
	<div class="content_box p-4">
		<div
			class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
			<h2 class="title_block mb-0">Добавленные клубы</h2>
			<a
				@click.prevent="modals_visible = true"
				href="#"
				data-bs-toggle="offcanvas"
				class="btn btn-primary btn-rounded btn-primary-shadow">
				<vue-feather class="d-xs-none" type="plus-circle"></vue-feather>Добавить
			</a>
		</div>

		<div class="row g-4">
			<div
				v-for="(item, i) in clubs"
				:key="i"
				:class="
					clubs.length % 3 == 0 || clubs.length % 5 == 0
						? 'col-md-4'
						: 'col-md-6'
				">
				<ClubCard
					:item="item"
					@changeClub="fetchClubs"
					@deleteClub="deleteClub" />
			</div>
		</div>
		<div v-if="loaded && !hasContent" class="table__no_content text-center fs-3 py-3 text-muted">
			<img src="/assets/img/table_no_content.svg">
			<div>Еще нет добавленных клубов</div>
		</div>
	</div>
	<AddClub
		@changeData="fetchClubs()"
		:visible="modals_visible"
		@hide="modals_visible = false" />
</template>

<script>
import AddClub from "../offcanvases/AddClub.vue"
import ClubCard from "../../components/cards/ClubCard.vue"
import { mapGetters, mapActions } from "vuex"
import api from "@/store/api"
export default {
	components: {
		ClubCard,
		AddClub,
	},
	data() {
		return {
			modals_visible: false,
			loaded: false
		}
	},
	async mounted() {
		await this.fetchClubs()
		this.loaded = true
	},
	methods: {
		testCallback() {
			console.log("testCallback")
		},
		...mapActions({
			fetchClubs: "profile/fetchClubs",
		}),
		editClub(id) {
			console.log("Edit ", id)
		},
		async deleteClub(id) {
			await api
				.delete(
					`${process.env.VUE_APP_BASE_URL}club/${id}/delete`,
					this.form_data
				)
				.then((res) => {
					this.fetchClubs()
				})
				.catch((error) => {
					console.log(error)
					this.error_message = error.response.data.message
					this.$swal(error.response.data.message, "", "danger")
				})
		},
	},
	computed: {
		hasContent() {
			if(_.isEmpty(this.clubs)){
				return false
			} else {
				return true
			}
		},
		...mapGetters({
			clubs: "profile/getClubs",
		}),
	},
}
</script>

<style></style>
