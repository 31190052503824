import axios from "axios"
import router from "@/router"

export const eventsStateModule = {
    state: () => ({
        event_cats: [],
        event_post: {},
        stage_post: {},
        events_results: [],
        distances_places: [],
        stage_order_athletes: []
    }),
    getters: {
        getEventCats(state) {
            return state.event_cats
        },
        getEventCatById: (state) => (id) => {
            return state.event_cats.find(cat => cat.id === id)
        },
        getEventPost(state) {
            return state.event_post
        },
        getStagePost(state) {
            return state.stage_post
        },
        getStageDistanceByID: (state) => (id) => {
            return state.stage_post.distances.find(item => item.id === id)
        },
        getRelayRaceByID: (state) => (id) => {
            return state.stage_post.relay_races.find(item => item.id === id)
        },
        getEventResults(state) {
            return state.events_results
        },
        getDistancePlaces: (state) => (id) => {
            let distance_places = state.distances_places.find(item => item.id === id)
            if(distance_places) return distance_places.places
            return
        },
        getStageOrdersAthletes(state) {
            return state.stage_order_athletes
        },
    },
    mutations: {
        setEventCats(state, event_cats) {
            state.event_cats = event_cats
        },
        setEvent(state, event_post) {
            state.event_post = event_post
        },
        setStage(state, stage_post) {
            state.stage_post = stage_post
        },
        setStageOrdersAthletes(state, response) {
            state.stage_order_athletes = response
        },
        setEventResults(state, response) {
            state.events_results = response
        },
        setDistancePlaces(state, response) {
            let i = state.distances_places.findIndex(e => e.id === response.id)
            if(i > -1){
                state.distances_places[i] = response
            } else {
                state.distances_places.push(response)
            }
        },
        setDistanceDisabled(state, id) {
            let i = state.distances_places.findIndex(e => e.id === id)
            if(i > -1){
                state.distances_places[i].disabled = true
            }
        },
    },
    actions: {
        async fetchEventCats({state, commit}){
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL+'events/cards')
                commit('setEventCats', response.data)
            } catch (e){
                if(e.response.status === 404){
                    router.push({name: 'not_found'})
                } else {
                    alert("Ошибка получения данных с сервера о категориях мероприятий")
                }
            }
        },
        async fetchEvent({state, commit}, id){
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL+'events/'+id)
                commit('setEvent', response.data)
            } catch (e){
                if(e.response.status === 404){
                    router.push({name: 'not_found'})
                } else {
                    alert("Ошибка получения данных с сервера о странице")
                }
            }
        },
        async fetchStage({state, commit}, id){
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL+'event-stage/'+id)
                commit('setStage', response.data)
            } catch (e){
                if(e.response.status === 404){
                    router.push({name: 'not_found'})
                } else {
                    alert("Ошибка получения данных с сервера о странице")
                }
            }
        },
        async fetchDistancePlaces({state, commit}, id){
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL+'distances/'+id+'/getplaces')
                commit('setDistancePlaces', response.data)
            } catch (e){
                console.log(e.response)
                alert("Ошибка получения данных с сервера о доступности мест")
            }
        },
        async fetchStageOrdersAthletes({state, commit}, id){
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL+'event-stage/'+id+'/orders-athletes')
                commit('setStageOrdersAthletes', response.data)
            } catch (e){
                if(e.response.status === 404){
                    router.push({name: 'not_found'})
                } else {
                    alert("Ошибка получения данных с сервера о странице")
                }
            }
        },
        async fetchEventResults({state, commit}){
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL+'results/archive')
                commit('setEventResults', response.data)
            } catch (e){
                if(e.response.status === 404){
                    router.push({name: 'not_found'})
                } else {
                    alert("Ошибка получения данных с сервера о результатах")
                }
            }
        },
    },
    namespaced: true
}