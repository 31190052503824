<template>
	<div class="home_tabs" id="home_tabs">
		<div class="container">
			<div class="home_tabs__header d-flex nav nav-tabs" id="nav-tab" role="tablist">
				<button
					@click.prevent="active_tab = 99"
					class="home_tabs__item"
					:class="active_tab === 99 ? 'active' : ''">
					<p class="title">Все мероприятия</p>
					<p class="subtitle text-muted mb-0"></p>
				</button>

				<button
					v-for="(cat, index) in eventTabs"
					:key="index"
					@click.prevent="active_tab = index"
					class="home_tabs__item"
					:class="active_tab === index ? 'active' : ''">
					<p class="title">{{ cat.name }}</p>
					<p class="subtitle text-muted mb-0">{{ cat.description }}</p>
				</button>
			</div>
			<div class="home_tabs__header--mobile">
				<CDropdown class="w-100">
					<CDropdownToggle
						class="home_tabs__item d-flex align-items-center justify-content-between">
						<div class="me-4">
							<p class="title text-start">
								{{ active_tab_title ? active_tab_title : 'Все мероприятия' }}
							</p>
							<p class="subtitle text-muted mb-0 text-start">
								{{ active_tab_subtitle ? active_tab_subtitle : '' }}
							</p>
						</div>
						<vue-feather type="chevron-down" class="text-muted" />
					</CDropdownToggle>
					<CDropdownMenu class="w-100">
						<CDropdownItem>
							<button
								@click.prevent="
									;(active_tab = 99), (active_tab_title = ''), (active_tab_subtitle = '')
								"
								class="home_tabs__item"
								:class="active_tab === 99 ? 'active' : ''">
								<p class="title">Все мероприятия</p>
								<p class="subtitle text-muted mb-0">Короткое описание</p>
							</button>
						</CDropdownItem>
						<CDropdownItem v-for="(cat, index) in eventTabs" :key="index">
							<button
								@click.prevent="
									;(active_tab = index),
										(active_tab_title = cat.name),
										(active_tab_subtitle = cat.description)
								"
								class="home_tabs__item"
								:class="active_tab === index ? 'active' : ''">
								<p class="title">{{ cat.name }}</p>
								<p class="subtitle text-muted mb-0">{{ cat.description }}</p>
							</button>
						</CDropdownItem>
					</CDropdownMenu>
				</CDropdown>
			</div>
		</div>
		<div class="home_tabs__line"></div>
		<div class="home_tabs__content">
			<div class="container tab-content">
				<div v-if="this.active_tab === 99" class="tab_pane">
					<div class="row g-4">
						<div v-for="(event, i) in getAllEvents" :key="i" class="col-lg-4 col-md-6">
							<event-card :event="event" />
						</div>
					</div>
				</div>

				<div v-for="(cat, index) in eventTabs" :key="index" class="tab_pane">
					<div v-if="this.active_tab === index" class="row g-4">
						<div v-for="(event, i) in cat.event_cards" :key="i" class="col-lg-4 col-md-6">
							<event-card :event="event" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EventCard from '@/components/cards/EventCard.vue'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
	components: {
		EventCard,
		CDropdown,
		CDropdownToggle,
		CDropdownMenu,
		CDropdownItem,
	},
	data() {
		return {
			active_tab: 99,
			active_tab_title: '',
			active_tab_subtitle: '',
		}
	},
	computed: {
		...mapGetters({
			eventTabs: 'home/eventTabs',
		}),
		getAllEvents() {
			let all_events = []
			this.eventTabs.forEach((element) => {
				all_events.push(...element.event_cards)
			})
			all_events = all_events.sort(
				(a, b) =>
					moment(a.start_event_date, 'DD-MM-YYYY HH:mm') -
					moment(b.start_event_date, 'DD-MM-YYYY HH:mm')
					
			)
			return all_events
		},
	},
}
</script>

<style scoped>
@media (max-width: 768px) {
	.home_tabs__header--mobile {
		display: block;
	}

	.home_tabs__header {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.home_tabs__header {
		display: block;
	}

	.home_tabs__header--mobile {
		display: none;
	}
}

.home_tabs__header--mobile .btn-group > .home_tabs__item {
	padding: 15px;
	border: 1px solid #ebe9f1;
	margin-bottom: 15px;
	background: #fff;
	box-shadow: none !important;
}

.home_tabs__header--mobile .btn-group > .home_tabs__item:after {
	display: none;
}

.home_tabs__header--mobile .dropdown-item {
	padding: 0;
}

.home_tabs__header--mobile .home_tabs__item .subtitle {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 300;
}
</style>