<template>
<div class="post_nav mt-4">
    <div class="row gx-4">
        <div class="col-6">
            <div v-if="prevId" class="post_nav__item d-flex align-items-center prev">
                <router-link :to="link+prevId" @click="$emit('changePage')" class="post_nav__arrow d-block me-2">
                    <vue-feather type="arrow-left-circle" />
                </router-link>
                <div v-if="prevName" class="post_nav__desc d-none d-md-block">
                    <p class="fw-bold mb-1">Назад</p>
                    <p class="small text-muted mb-0">{{prevName}}</p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div v-if="nextId" class="post_nav__item d-flex align-items-center justify-content-end next">
                <div v-if="nextName" class="post_nav__desc text-end d-none d-md-block">
                    <p class="fw-bold mb-1">Вперед</p>
                    <p class="small text-muted mb-0">{{nextName}}</p>
                </div>
                <router-link @click="$emit('changePage')" :to="link+nextId" class="post_nav__arrow d-block ms-2">
                    <vue-feather type="arrow-right-circle" />
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: [
        'link',
        'prevId',
        'prevName',
        'nextId',
        'nextName',
    ],
    emits: ['changePage'],
}
</script>

<style>

</style>
