<template>
<div v-if="item.distances && item.distances.length > 0" class="orders__item orders__item--extended">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <p class="product_name fw-bold mb-0">{{item.name}}</p>
        <p class="price text-success fw-bold mb-0">{{item.quantity}} x {{item.price}} ₽</p>
    </div>
    <OrderListServiceItemExpand 
        v-for="(elem, i) in item.distances"
        :key="i"
        :item="elem"
    />
</div>
<div v-else class="orders__item">
    <p class="product_name fw-bold mb-0">{{item.name}}</p>
    <p class="price text-success fw-bold mb-0">{{item.quantity}} x {{item.price}} ₽</p>
</div>
</template>

<script>
import OrderListServiceItemExpand from './OrderListServiceItemExpand'
export default {
  components: { OrderListServiceItemExpand },
    props: ['item'],
}
</script>

<style>

</style>
