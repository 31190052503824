<template>
<div class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2 text-muted">Состав заказа</h2>
    </div>
    <div class="orders">
        <div v-if="athletes_count && campShift" class="orders__item">
            <p class="product_name fw-bold mb-0">{{title}} - {{campShift.name}}, {{campShift.dates_str}}</p>
            <p class="price text-success fw-bold mb-0">{{athletes_count}} x {{campShift.price}} ₽</p>
        </div>

    </div>
    <div v-if="calculateSumm" class="orders__summ d-flex justify-content-between text_20 fw-bold mt-4">
        <span>Итого: </span>
        <span class="text-success">{{calculateSumm}} ₽</span>
    </div>
</div>
</template>

<script>
import OrderListServiceItem from './OrderListServiceItem'
export default {
  components: { OrderListServiceItem },
    props: ['athletes_count', 'postdata', 'title', 'camp_shift_id'],
    computed: {
        campShift(){
            let camp_shift_id = this.camp_shift_id
            let camp_shift = this.postdata.shifts.find(item => {
                return item.id == camp_shift_id
            })
            return camp_shift
        },
        calculateSumm(){
            let summ = 0
            summ += this.athletes_count * this.campShift.price
            return summ
        }
    }
}
</script>

<style>

</style>
