<template>
<div class="header_navbar d-flex align-items-center ms-auto">
    <header-tools-search-icon />
    <HeaderToolsNotifications v-if="userdata" />
    <template v-if="loaded">
        <HeaderToolsUser v-if="userdata" :user="userdata" />
        <div v-else class="d-flex align-items-center">
            <router-link to="/auth" class="btn btn-rounded fw-bold py-2 px-3">Вход</router-link>
            <router-link to="/auth/registration" class="btn btn-rounded fw-bold py-2 px-3">Регистрация</router-link>
        </div>
        <HeaderPhtCartButton v-if="$route.path.includes('photobank')" />
        <HeaderShopCartButton v-if="$route.path.includes('shop')" />
    </template>
    <slot />
</div>
</template>

<script>
import HeaderShopCartButton from './HeaderShopCartButton.vue'
import HeaderPhtCartButton from './HeaderPhtCartButton'
import HeaderToolsUser from './HeaderToolsUser.vue'
import HeaderToolsNotifications from './HeaderToolsNotifications.vue'
import HeaderToolsSearchIcon from './HeaderToolsSearchIcon.vue'
import {
    mapGetters, mapActions
} from "vuex";
export default {
    data(){
        return {
            loaded: false
        }
    },
    async mounted(){
        if(localStorage.getItem('access_token')){
            await this.fetchUserData()
        }
        this.loaded = true
    },
    computed: {
        ...mapGetters({
            userdata: "profile/getUserData",
        }),
    },
    methods:{
        ...mapActions({
            fetchUserData: "profile/fetchUserData",
        }),
    },
    components: {
    HeaderPhtCartButton,
        HeaderToolsSearchIcon,
        HeaderToolsNotifications,
        HeaderToolsUser,
        HeaderShopCartButton
    },
}
</script>

<style scoped>
.header_navbar .dropdown-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.header_navbar .dropdown-menu:last-child {
    border-bottom: 0;
}

.header_navbar .dropdown-menu {
    border: none;
    box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
}

.btn:hover{
    background: #fff;
}
</style>
