<template>
  <div class="search_link header_navbar__item me-2">
      <a @click="toggleOffcanvas('search_box')" href="#" class="btn btn-circle">
        <vue-feather type="search"></vue-feather>
      </a>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
        ...mapMutations({
            toggleOffcanvas: "global/toggleOffcanvas",
        }),
    }
}
</script>

<style>

</style>