import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthLoginView from '../views/auth/AuthLoginView.vue'
import ForgotPasswordView from '../views/auth/ForgotPasswordView.vue'
import ResetPasswordView from '../views/auth/ResetPasswordView.vue'
import AuthRegistrationView from '../views/auth/AuthRegistrationView.vue'
import ProfileView from '../views/ProfileView.vue'
import OrderRouter from '../views/order/OrderRouter.vue'
import FormCompetition from '../views/forms/FormCompetition.vue'
import FormRouter from '../views/forms/FormRouter.vue'
import FormCampView from '../views/forms/FormCampView.vue'
import RequestCompetition from '../views/RequestCompetition.vue'
import EventsAll from '../views/EventsAll.vue'
import RecordsView from '../views/RecordsView.vue'
import RatingView from '../views/RatingView.vue'
import TournamentTablesView from '../views/TournamentTablesView.vue'
import ResultsArchiveView from '../views/ResultsArchiveView.vue'
import EventsRouter from '../views/EventsRouter.vue'
import StageView from '../views/StageView.vue'
import CampView from '../views/CampView.vue'
import MasterClassView from '../views/MasterClassView.vue'
import NewsAllView from '../views/NewsAllView.vue'
import NewsPostView from '../views/NewsPostView.vue'
import PageView from '../views/PageView.vue'
import MediaAllView from '../views/MediaAllView.vue'
import MediaPostView from '../views/MediaPostView.vue'
import ContactsView from '../views/ContactsView.vue'

import MainView from '../views/photobank/MainView.vue'
import EventAlbum from '../views/photobank/EventAlbum.vue'
import SearchPhotosView from '../views/photobank/SearchPhotosView.vue'
import CheckoutView from '../views/photobank/CheckoutView.vue'
import PhotobankOrder from '../views/photobank/PhotobankOrder.vue'

import NotFound404 from '../views/NotFound404.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
	},
	{
		path: '/auth',
		name: 'login',
		component: AuthLoginView,
	},
	{
		path: '/auth/forgot-password',
		name: 'forgot-password',
		component: ForgotPasswordView,
	},
	{
		path: '/auth/reset-password/:email/:token',
		name: 'reset_password',
		component: ResetPasswordView,
	},
	{
		path: '/auth/registration',
		name: 'registration',
		component: AuthRegistrationView,
	},
	{
		path: '/auth/email/verify',
		name: 'email_verify',
		component: AuthLoginView,
	},
	{
		path: '/profile',
		name: 'profile',
		component: ProfileView,
	},
	{
		path: '/order/:id',
		name: 'order',
		component: OrderRouter,
	},
	{
		path: '/order/:id/edit',
		name: 'order_edit',
		component: OrderRouter,
	},
	// На удаление
	{
		path: '/form/competition',
		name: 'form_competition',
		component: FormCompetition,
	},
	{
		path: '/form/camp',
		name: 'form_camp',
		component: FormCampView,
	},
	{
		path: '/form/master_class',
		name: 'form_camp',
		component: FormCampView,
	},
	// -------
	{
		path: '/event-form',
		name: 'event_form',
		component: FormRouter,
	},
	{
		path: '/request/:id',
		name: 'request',
		component: RequestCompetition,
	},
	{
		path: '/events',
		name: 'events',
		component: EventsAll,
	},
	{
		path: '/records',
		name: 'records',
		component: RecordsView,
	},
	{
		path: '/rating',
		name: 'rating',
		component: RatingView,
	},
	{
		path: '/tournaments',
		name: 'tournaments',
		component: TournamentTablesView,
	},
	{
		path: '/results',
		name: 'results',
		component: ResultsArchiveView,
	},
	{
		path: '/events/:id',
		name: 'event',
		component: EventsRouter,
	},
	{
		path: '/stage/:id',
		name: 'stage',
		component: StageView,
	},
	{
		path: '/camp/:id',
		name: 'camp',
		component: CampView,
	},
	{
		path: '/master-class/:id',
		name: 'master-class',
		component: MasterClassView,
	},
	{
		path: '/news',
		name: 'news',
		component: NewsAllView,
	},
	{
		path: '/news/:id',
		name: 'news_post',
		component: NewsPostView,
	},
	{
		path: '/page/:id',
		name: 'page',
		component: PageView,
	},
	{
		path: '/media',
		name: 'media',
		component: MediaAllView,
	},
	{
		path: '/media/:id',
		name: 'media_post',
		component: MediaPostView,
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: ContactsView,
	},

	//Фотобанк

	{
		path: '/photobank',
		name: 'photobank',
		component: MainView,
	},
	{
		path: '/photobank/album/:id',
		name: 'photobank_album',
		component: EventAlbum,
	},
	{
		path: '/photobank/search/',
		name: 'photobank_search',
		component: SearchPhotosView,
	},
	{
		path: '/photobank/checkout',
		name: 'photobank_checkout',
		component: CheckoutView,
	},
	{
		path: '/photobank/order/:id',
		name: 'photobank_order',
		component: PhotobankOrder,
	},

	// Конец Фотобанка


	// Магазин
	{
		path: '/shop',
		name: 'shop',
		component: () => import('@/views/shop/ShopView.vue'),
	},
	{
		path: '/shop/:product',
		name: 'product_page',
		component: () => import('@/views/shop/ShopProductView.vue'),
	},
	{
		path: '/shop/home',
		name: 'shop_home',
		component: () => import('@/views/shop/ShopHomeView.vue'),
	},
	{
		path: '/shop/order/:post',
		name: 'shop_order',
		component: () => import('@/views/shop/ShopOrderView.vue'),
	},
	{
		path: '/shop/checkout',
		name: 'shop_checkout',
		component: () => import('@/views/shop/ShopCheckoutView.vue'),
	},


	{
		path: '/:pathMatch(.*)*',
		name: 'not_found',
		component: NotFound404,
	},
	//{
	//path: '/about',
	//name: 'about',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	//component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	//}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

export default router
