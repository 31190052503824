<template>
   <div v-for="(item, i) in accept_checks" :key="i" class="form-check">
        <input 
            v-model="accept_checks[i].check" 
            :id="'accept_'+i"
            class="form-check-input ml-2" 
            :class="show_errors && !accept_checks[i].check ? 'invalid' : ''"
            type="checkbox" 
            required>
        <label 
            class="form-check-label small"
            :class="show_errors && !accept_checks[i].check ? 'text-danger' : ''"
            :for="'accept_'+i" 
            v-html="item.text">
        </label>
    </div>
    <div class="row g-3 mt-1">
        <div class="col-md-4">
            <a @click.prevent="activeStep = activeStep - 1" v-if="activeStep > 0" href="#" class="btn btn-outline btn-outline-secondary btn-rounded w-100">Назад</a>
        </div>
        <div class="col-md-4">
            <button v-if="!hideDraftButton" @click.prevent="$emit('saveAsDraft')" class="btn btn-warning btn-rounded w-100" :disabled="has_errors">Сохранить как черновик</button>
        </div>
        <div class="col-md-4">
            <button @click.prevent="$emit('sendOrder')" class="btn btn-primary btn-primary-shadow btn-rounded w-100">{{sendBtnText}}</button>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
export default {
    props: {
        accept_checks: {
            type: Array,
            default: () => ([])
        },
        show_errors: {
            type: Boolean,
            default: false
        },
        has_errors: {
            type: Boolean,
            default: false
        },
        hasLastErrors: {
            type: Boolean,
            default: false
        },
        activeStep: {
            type: Number,
            default: 4
        },
        first_status: {
            type: String
        },
        hideDraftButton: {
            type: Boolean,
            default: false
        },
        extra_pay: {
            type: Number,
            default: null
        },
    },
    computed: {
        sendBtnText(){
            if(this.first_status != 'pending_payment') return 'Отправить'
            if(this.extra_pay == null) return 'Перейти к оплате'
            if(this.extra_pay <= 0) return 'Отправить'
            return 'Перейти к оплате'
        }
    },
    setup(props, { emit }) {
        const accept_checks = computed({  
            get: () => props.accept_checks,
            set: (value) => emit('update:accept_checks', value),
        });
        const activeStep = computed({  
            get: () => props.activeStep,
            set: (value) => emit('update:activeStep', value),
        });
        return {accept_checks, activeStep};
    },
    emits: ['update:accept_checks', 'update:activeStep', 'sendOrder', 'saveAsDraft'],
}
</script>

<style>

</style>