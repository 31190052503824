<template>
	<PageLoader v-if="!fetched_data" />
	<with-sidebar-layout v-if="fetched_data">
		<template #head>
			<page-title
				title="Заявка"
				:breadcrumbs="[{ title: 'Мой аккаунт', link: '/profile' }]" />
		</template>
		<template #content>
			<OrderResultMessage
				v-if="show_order_message"
				:status="orderdata.payment_status"
				class="mb-4" />
			<div class="content_box p-4 mb-4">
				<div
					class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
					<h2 class="title_block mb-2 fs-3">Заявка #{{ orderdata.id }}</h2>
				</div>
				<div class="row g-4">
					<div
						v-if="postData.type == 'camp' && postData.name && postData.id"
						class="col-md-3 small">
						<p class="text-muted mb-1">Мероприятие</p>
						<router-link :to="`/stage/${postData.id}`" class="mb-0 fw-bold">
							{{ postData.name }}
						</router-link>
					</div>
					<div
						v-if="postData.type == 'master_class' && postData.name && postData.id"
						class="col-md-3 small">
						<p class="text-muted mb-1">Мастер-класс</p>
						<router-link :to="`/event/${postData.id}`" class="mb-0 fw-bold">
							{{ postData.name }}
						</router-link>
					</div>
					<div v-if="campShift && campShift.name" class="col-md-2 small">
						<p class="text-muted mb-1">Смена</p>
						<p class="mb-0">
							{{ campShift.name }}
						</p>
					</div>
					<div v-if="campShift && campShift.dates_str" class="col-md-3 small">
						<p class="text-muted mb-1">Даты смены</p>
						<p class="mb-0">
							{{ campShift.dates_str }}
						</p>
					</div>
					<div
						v-if="postData.type == 'master_class' && postData.dates"
						class="col-md-3 small">
						<p class="text-muted mb-1">Даты</p>
						<p class="mb-0">
							{{ postData.dates }}
						</p>
					</div>
					<div v-if="postData.contacts_address" class="col-md-4 small">
						<p class="text-muted mb-1">Место проведения</p>
						<p class="mb-0">
							{{ postData.contacts_address }}
						</p>
					</div>
					<div v-if="orderdata.sent_date" class="col-auto small">
						<p class="text-muted mb-1">Дата заявки</p>
						<p class="mb-0">{{ orderdata.sent_date }}</p>
					</div>
				</div>
			</div>

			<div class="content_box p-4 mb-4">
				<div
					class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
					<h2 class="title_block mb-2">Спортсмены</h2>
				</div>
				<div class="data_table">
					<table class="table table-hover table-responsive">
						<thead>
							<tr>
								<th scope="col fw-bold small">ФИО</th>
								<th scope="col fw-bold small">Дата рождения</th>
								<th scope="col fw-bold small">Пол</th>
								<th scope="col fw-bold small">Разряд</th>
								<th scope="col fw-bold small">Гражданство</th>
								<th scope="col fw-bold small" class="text-end">Документы</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, i) in form_data.athletes" :key="i">
								<td class="small">
									<div class="table_name_item d-flex align-items-center">
										<Avatar
											:src="item.athlete.avatar"
											:name="item.athlete.name"
											:class="'me-2'" />
										<span class="fw-bold lh-1">{{ item.athlete.name }}</span>
									</div>
								</td>
								<td class="small align-middle">{{ item.athlete.birthdate }}</td>
								<td class="small align-middle">
									{{ item.athlete.gender == 'M' ? 'Юноша' : 'Девушка' }}
								</td>
								<td class="small align-middle">
									<span v-if="item.athlete.category" class="badge bg-warning">{{
										item.athlete.category
									}}</span>
								</td>
								<td class="small align-middle">
									{{ item.athlete.nation }}
								</td>
								<td class="small align-middle text-end">
									<vue-feather
										v-if="item.online_request"
										type="check-square"
										class="text-success" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<AdditionalData
				:contacts="{
					name: orderdata.agent_name,
					phone: orderdata.agent_phone,
					email: orderdata.agent_email,
					city: orderdata.agent_city,
				}" />

			<p class="text-decorative fs-2 text-uppercase mb-3">Информация о заказе</p>
			<div class="row g-4">
				<div class="col-md-6">
					<OrdersListCamp
						v-if="postData.type == 'camp'"
						:camp_shift_id="form_data.event_child_id"
						:athletes_count="form_data.athletes.length"
						:postdata="postData"
						:title="postData.name" />
					<OrdersListMasterClass
						v-if="postData.type == 'master_class'"
						:athletes_count="form_data.athletes.length"
						:postdata="postData"
						:title="postData.name"
						:entry_fee="postData.entry_fee" />
				</div>
				<div class="col-md-6">
					<RequestOrderInfo
						:id="orderdata.id"
						:payment_status="this.form_data.payment_status"
						:summ="orderdata.summ"
						:paytime_end="orderdata.paytime_end"
						@send="toPayment()"
						@time_end="time_end()" />
					<div
						v-if="
							orderdata.payers_name || orderdata.payers_phone || orderdata.payers_email
						"
						class="content_box p-4 mb-4">
						<div
							class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
							<h2 class="title_block mb-2 text-muted">Плательщик</h2>
						</div>
						<table class="list_box__content">
							<tr v-if="orderdata.payers_name">
								<td class="text-muted">ФИО:</td>
								<td class="ps-3">{{ orderdata.payers_name }}</td>
							</tr>
							<tr v-if="orderdata.payers_phone">
								<td class="text-muted">Телефон:</td>
								<td class="ps-3">{{ orderdata.payers_phone }}</td>
							</tr>
							<tr v-if="orderdata.payers_email">
								<td class="text-muted">Email:</td>
								<td class="ps-3">{{ orderdata.payers_email }}</td>
							</tr>
						</table>
					</div>

					<div v-if="payment_method_desc" class="content_box p-4 mb-4">
						<div
							class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
							<h2 class="title_block mb-2 text-muted">Способ оплаты</h2>
						</div>
						<p class="fw-bold mb-1">{{ payment_method_desc.title }}</p>
						<p class="text-muted small mb-0">{{ payment_method_desc.desc }}</p>
					</div>
				</div>
			</div>
		</template>
		<template #sidebar>
			<RequestSidebarBox
				:payment_status="this.form_data.status"
				:summ="orderdata.summ"
				:paytime_end="orderdata.paytime_end"
				@send="toPayment()"
				@time_end="time_end()" />
		</template>
	</with-sidebar-layout>
</template>

<script>
import PageLoader from '../components/PageLoader'
import OrderResultMessage from '../components/OrderResultMessage.vue'
import OrdersListMasterClass from '../components/request/OrdersListMasterClass.vue'
import AdditionalData from '../components/request/AdditionalData.vue'
import OrdersListCamp from '../components/request/OrdersListCamp.vue'
import Avatar from '../components/UI/Avatar.vue'
import PageTitle from '../components/PageTitle.vue'
import RequestOrderInfo from '../components/RequestOrderInfo.vue'
import OrdersList from '../components/request/OrdersList.vue'
import SportsmensTable from '@/components/request/SportsmensTable.vue'
import RequestSidebarBox from '../components/RequestSidebarBox.vue'
import WithSidebarLayout from '../layouts/WithSidebarLayout.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	props: {
		postData: {
			type: Object,
			default: {},
		},
		orderdata: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			form_data: {
				id: null,
				status: null,
				payment_status: null,
				type: 'camp',
				event_id: null,
				event_child_id: null,
				agent_name: null,
				agent_phone: null,
				agent_email: null,
				agent_city: null,
				payers_name: null,
				payers_phone: null,
				payers_email: null,
				payment_method: 1,
				athletes: [
					{
						athlete_id: null,
						athlete: null,
						online_request: false,
						athlete_documents: [],
					},
				],
			},
			payment_method_desc: null,
			show_order_message: false,
			fetched_data: false,
		}
	},
	computed: {
		campShift() {
			if (this.postData.type == 'camp') {
				let camp_shift_id = this.orderdata.event_stage_id
				let camp_shift = this.postData.shifts.find((item) => {
					return item.id == camp_shift_id
				})
				return camp_shift
			}
			return false
		},
		...mapGetters({
			getAthleteByID: 'athlets/getAthleteByID',
			payment_methods: 'global/getPaymentMethods',
		}),
	},
	async mounted() {
		await this.fetchAthletes()
		await this.fetchAthletesAll()
		this.transformOrderData()
		this.insertAthletesInfo()
		await this.checkPaymentStatus()
		this.fetched_data = true
	},
	methods: {
		...mapActions({
			fetchAthletes: 'profile/fetchAthletes',
			fetchAthletesAll: 'athlets/fetchAthletes',
			fetchPaymentStatus: 'order/fetchPaymentStatus',
		}),
		transformOrderData() {
			if (this.orderdata) {
				this.form_data.status = this.orderdata.status
				this.form_data.payment_status = this.orderdata.payment_status
				this.form_data.id = this.orderdata.id
				this.form_data.type = this.orderdata.type
				this.form_data.event_id = this.orderdata.event_id
				this.form_data.event_child_id = this.orderdata.event_stage_id
				this.form_data.agent_name = this.orderdata.agent_name
				this.form_data.agent_phone = this.orderdata.agent_phone
				this.form_data.agent_email = this.orderdata.agent_email
				this.form_data.agent_city = this.orderdata.agent_city
				this.payment_method_desc = this.payment_methods.find(
					(item) => item.id == this.orderdata.payment_method
				)

				if (this.orderdata.athletes.length > 0) {
					let athletes_array = []
					this.orderdata.athletes.forEach((item, i) => {
						//console.log(distances_array)
						athletes_array.push({
							athlete_id: item.id,
							online_request: _.isEmpty(item.athlete_documents) ? false : true,
							athlete_documents: item.athlete_documents,
						})
					})
					if (!_.isEmpty(athletes_array)) this.form_data.athletes = athletes_array
				}
			}
		},
		insertAthletesInfo() {
			this.form_data.athletes.forEach((item) => {
				if (item.athlete_id) {
					item.athlete = this.getAthleteByID(item.athlete_id)
				}
			})
		},
		toPayment() {
			if (this.orderdata.payment_url) {
				window.location.href = this.orderdata.payment_url
			}
		},
		time_end() {
			this.form_data.status = 'rejected'
			this.form_data.payment_status = 'rejected'
		},
		async checkPaymentStatus() {
			if (
				this.postData.first_status == 'pending_payment' &&
				this.form_data.payment_status == 'pending' &&
				this.$route.query.result == 1
			) {
				let res = await this.fetchPaymentStatus(this.form_data.id)
				this.form_data.status = this.orderdata.status
				this.form_data.payment_status = this.orderdata.payment_status
				this.show_order_message = true
			}
		},
	},
	components: {
    PageLoader,
		WithSidebarLayout,
		RequestSidebarBox,
		SportsmensTable,
		OrdersList,
		RequestOrderInfo,
		PageTitle,
		Avatar,
		OrdersListCamp,
		AdditionalData,
		OrdersListMasterClass,
		OrderResultMessage,
	},
}
</script>

<style></style>
