<template>
	<div class="col-xxl-6">
		<div class="input_add_edit row gx-1">
			<div class="col-auto">
				<label for="add_edit">
					<span class="fw-bold"
						>Выберите участника <span class="text-danger">*</span></span
					>
				</label>
			</div>
			<div class="col text-end">
				<a
					v-if="!searchInDatabase"
					@click.prevent=";(searchInDatabase = !searchInDatabase), clearData()"
					href="#"
					class="text-muted small"
					>Выбрать из базы</a
				>
				<a
					v-if="searchInDatabase"
					@click.prevent=";(searchInDatabase = !searchInDatabase), clearData()"
					href="#"
					class="text-muted small"
					>Выбрать из аккаунта</a
				>
			</div>
			<div class="col-12">
				<div class="row g-2">
					<div class="col-11">
						<select
							v-if="!searchInDatabase"
							v-model="theModel"
							@change="selectAthleteByID"
							class="form-select"
							:class="show_errors && !theModel ? 'invalid' : ''">
							<option :value="null" disabled>Выберите участника</option>
							<option
								v-for="(item, i) in ATHLETES"
								:key="i"
								:value="item.id"
								:disabled="isAthleteDisabled(item)">
								{{ item.name + ' - ' + item.birthdate + ' г.р.' }}
							</option>
						</select>
						<VSelect
							v-if="searchInDatabase"
							v-model="theModel"
							label="list_name"
							:options="ALL_ATHLETES"
							@search="onSearch"
							:selectable="(option) => !isAthleteDisabled(option)"
							:reduce="(option) => option.id"
							@option:selected="selectAthlete"
							:dropdown-should-open="showOptionsList"
							placeholder="Начните вводить имя"
							:class="show_errors && !theModel ? 'invalid' : ''">
							<template #no-options>
								<div>Нет подходящих вариантов</div>
							</template>
						</VSelect>
						<p v-if="show_errors && !theModel" class="mb-0 small text-danger">
							Обязательное поле
						</p>
					</div>
					<div class="col-1" style="padding: 5px 0 5px 0;"><button @click.prevent="visible_modals.create_athlete = true" class="btn btn-secondary w-100 h-100 fs-4 py-0 px-2">+</button></div>
				</div>
			</div>
			<div class="col-12">
				<a
					v-if="!searchInDatabase && theModel"
					@click.prevent="visible_modals.edit_athlete = true"
					href="#"
					class="text-muted small"
					>Редактировать выбранного</a
				>
			</div>
		</div>
		<EditAthlet
			:visible="visible_modals.edit_athlete"
			:athlete_data="athlete"
			@hide="visible_modals.edit_athlete = false" />
		<EditAthlet
			:visible="visible_modals.create_athlete"
			@hide="visible_modals.create_athlete = false" />
	</div>
</template>

<script>
import EditAthlet from '../offcanvases/EditAthlet.vue'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { computed } from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	props: {
		modelValue: {
			type: Number,
			default: () => ({}),
		},
		used_athletes: {
			type: Array,
			default: [],
		},
		disallowed_athletes: {
			type: Array,
			default: [],
		},
		show_errors: {
			type: Boolean,
		},
		age_limits: {
			type: Array,
			default: [],
		},
		athlete: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			selected_athlete: null,
			searchInDatabase: false,
			documents_full: false,
			visible_modals: {
				edit_athlete: false,
				create_athlete: false,
			},
		}
	},
	computed: {
		...mapGetters({
			ATHLETES: 'profile/getAthletes',
			ALL_ATHLETES: 'athlets/getAthletesListNames',
		}),
	},
	watch: {
		theModel: {
			handler(item) {
				this.checkAthleteFromDatabase()
			},
			deep: true,
		},
	},
	mounted() {
		this.checkAthleteFromDatabase()
	},
	methods: {
		...mapActions({
			fetchAthletesByName: 'athlets/fetchAthletesByName',
		}),
		async onSearch(search, loading) {
			if (search.length && search.length > 3) {
				loading(true)
				await this.fetchAthletesByName(search)
				loading(false)
			}
		},
		searchAthlete(search, loading) {
			console.log(search)
		},
		selectAthlete(athlete) {
			this.selected_athlete = athlete
			this.$emit('selectAthlete', athlete)
		},
		selectAthleteByID(e) {
			let athlete = this.ATHLETES.find((item) => {
				return item.id == parseInt(e.target.value)
			})
			this.selected_athlete = athlete
			this.$emit('selectAthlete', athlete)
		},
		clearData() {
			this.theModel = null
			this.$emit('selectAthlete', null)
		},
		showOptionsList(VueSelect) {
			if (this.theModel !== null) {
				return VueSelect.open
			}
			return VueSelect.search.length !== 0 && VueSelect.open
		},
		checkAthleteFromDatabase() {
			if (this.theModel) {
				let exist = this.ATHLETES.find((item) => {
					return item.id == this.theModel
				})
				if (!exist) this.searchInDatabase = true
			}
		},
		hasAgeLimitPlaces(age_limits = [], athlete) {
			if (!age_limits.length) return true
			let current_age_limit = age_limits.find((item) => {
				let athlete_year = athlete.birthdate.split('/').pop()
				if (athlete.gender === 'M') {
					return item.male_years.includes(athlete_year)
				} else if (athlete.gender === 'F') {
					return item.female_years.includes(athlete_year)
				}
				return item.gender === athlete.gender && item.years.includes(athlete_year)
			})
			if (!current_age_limit) return true
			return current_age_limit.has_vacant_places
		},
		isAthleteDisabled(athlete) {
			return (
				this.used_athletes.includes(athlete.id) ||
				!this.hasAgeLimitPlaces(this.age_limits, athlete) ||
				this.disallowed_athletes.includes(athlete.id)
			)
		},
	},
	setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		return { theModel }
	},
	emits: ['update:modelValue', 'selectAthlete'],
	components: { VSelect, EditAthlet },
}
</script>

<style></style>
